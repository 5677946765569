import axiosStore from '@/Api/ApiConfig'

const products = {
  namespaced: true,
  state: {
    listProducts: [],
    page: {
      current: 1,
      total: 0,
      per_page: 18
    },
    product: {},
    column: 'Destacado',
    order: 'DESC',
    search: '',
    checkPrice: true,
    loading: false,
    category: null,
    subcategory: null,
    variedad: null,
    currentBodega: [],
    filterBodega: '',
    letterBodega: '',
    currentCountry: [],
    currentMaridaje: [],
    precios: [],
    currentFilterPrice: [],
    many: [],
    manyCategories: [],
    galeria: [],
    bodegas: [],
    maridaje: [],
    paises: []
  },
  mutations: {
    updateManyCategories: (state, many) => { state.manyCategories = many },
    updateMany: (state, many) => {
      state.many = many
    },
    updateCheckPrice: (state, status) => {
      state.checkPrice = status
    },
    updatePrecios: (state, precios) => {
      state.precios = precios
    },
    updateCurrentFilterPrice: (state, precios) => {
      state.currentFilterPrice = precios
    },
    updateList: (state, list) => {
      state.listProducts = list
    },
    updatePage: (state, page) => {
      state.page = page
    },
    updateTotal: (state, total) => {
      state.page.total = total
    },
    updateCurrent: (state, current) => {
      state.page.current = current
    },
    updatePerPage: (state, perPage) => {
      state.page.per_page = perPage
    },
    updateCurrentNext: (state) => {
      state.current++
      if (state.current > state.page.total) {
        state.current = state.page.total
      }
    },
    updateCurrentPrevious: (state) => {
      state.current--
      if (state.current < 1) {
        state.current = 1
      }
    },
    updateSearch: (state, search) => {
      state.search = search
    },
    updateLoading: (state, loading) => {
      state.loading = loading
    },
    updateCategory: (state, category) => {
      state.category = category
    },
    updateSub: (state, subcategory) => {
      state.subcategory = subcategory
    },
    updateVariedad: (state, variedad) => {
      state.variedad = variedad
    },
    updateProduct: (state, product) => {
      state.product = product
    },
    updateColumn: (state, column) => {
      state.column = column
    },
    updateOrder: (state, order) => {
      state.order = order
    },
    updateGallery: (state, galeria) => { state.galeria = galeria },
    updateBodegas: (state, bodegas) => { state.bodegas = bodegas },
    updateCurrentBodega: (state, bodega) => { state.currentBodega = bodega },
    updateLetterBodega: (state, letter) => { state.letterBodega = letter },
    updateFilterBodega: (state, bodega) => { state.filterBodega = bodega },
    updateMaridaje: (state, maridaje) => { state.maridaje = maridaje },
    updateCurrentMaridaje: (state, maridaje) => { state.currentMaridaje = maridaje },
    updatePaises: (state, paises) => { state.paises = paises },
    updateCurrentPaises: (state, paises) => { state.currentCountry = paises }
  },
  actions: {
    apiBodegas: ({
      commit
    }) => {
      axiosStore.get('bodegas').then(response => {
        commit('updateBodegas', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    apiMaridaje: ({
      commit
    }) => {
      axiosStore.get('maridajes').then(response => {
        commit('updateMaridaje', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    apiPaises: ({
      commit
    }) => {
      axiosStore.get('paises').then(response => {
        commit('updatePaises', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    apiList: ({
      commit,
      state
    }) => {
      commit('updateLoading', true)
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`articulos?page=${state.page.current}&cant=${state.page.per_page}&column=${state.column}&order=${state.order}&lng=${lng.toString()}`)
        .then(result => {
          commit('updateLoading', false)
          const response = result.data
          if (response.data.length > 0) {
            const mapData = response.data
            commit('updateList', mapData)
          }
          if (response.total) {
            let total = parseInt(response.total / state.page.per_page)
            if (response.total > (total * state.page.per_page)) {
              ++total
            }
            commit('updateTotal', total)
            commit('updateCurrentNext')
          }
        }).catch(error => {
          commit('updateLoading', false)
          console.log(error)
        })
    },
    changeList: ({ commit }, list) => {
      commit('updateList', list)
    },
    changePage: ({ commit }, page) => {
      commit('updatePage', page)
    },
    apiPagination: ({
      commit,
      state,
      dispatch
    }, current) => {
      commit('updateLoading', true)
      commit('updateCurrent', current)
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      let url = `articulos?page=${current}&cant=${state.page.per_page}&column=${state.column}&order=${state.order}&lng=${lng.toString()}`
      if (Array.isArray(state.currentFilterPrice) && state.currentFilterPrice.length === 2 && state.checkPrice) {
        url += `&priceInit=${state.currentFilterPrice[0]}&priceEnd=${state.currentFilterPrice[1]}`
      }
      commit('updateCheckPrice', true)
      url += state.search ? `&name=${state.search}` : ''
      url += state.category ? `&familia=${state.category}` : ''
      url += state.subcategory ? `&sub=${state.subcategory}` : ''
      url += state.variedad ? `&varia=${state.variedad}` : ''
      if (state.manyCategories.length > 0) {
        url += `&many=${state.manyCategories}`
      }
      if (state.currentBodega.length > 0) {
        url += `&bodega=${state.currentBodega.join()}`
      }
      if (state.currentMaridaje.length > 0) {
        url += `&maridaje=${state.currentMaridaje.join()}`
      }
      if (state.currentCountry.length > 0) {
        url += `&country=${state.currentCountry.join()}`
      }
      axiosStore.get(url)
        .then(result => {
          commit('updateLoading', false)
          const response = result.data
          if (response.data.length > 0) {
            const mapData = response.data
            commit('updateList', mapData)
          } else {
            commit('updateList', [])
          }
          if (response.total) {
            let total = parseInt(response.total / state.page.per_page)
            if (response.total > (total * state.page.per_page)) {
              ++total
            }
            commit('updateTotal', total)
          } else {
            commit('updateTotal', 0)
          }
        }).catch(error => {
          commit('updateLoading', false)
          console.log(error)
        })
    },
    apiIndividual: ({ commit }, id) => {
      commit('updateLoading', true)
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`articulos?id=${id}&lng=${lng.toString()}`)
        .then(result => {
          commit('updateLoading', false)
          let response = result.data
          if (Array.isArray(response) && response.length > 0) {
            response = response[0]
          }
          commit('updateProduct', response)
        }).catch(error => {
          commit('updateLoading', false)
          console.log(error)
        })
    },
    apiIndividualSlug: ({ commit }, slug) => {
      commit('updateLoading', true)
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`articulos?slug=${slug}&lng=${lng.toString()}`)
        .then(result => {
          commit('updateLoading', false)
          let response = result.data
          if (Array.isArray(response) && response.length > 0) {
            response = response[0]
          }
          commit('updateProduct', response)
        }).catch(error => {
          commit('updateLoading', false)
          console.log(error)
        })
    },
    ApiManyProducts: ({ commit }, many) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`articulos/many?many=${many}&lng=${lng.toString()}`)
        .then(result => {
          const response = result.data
          commit('updateMany', response)
        }).catch(error => {
          console.log(error)
        })
    },
    ApiManyProductsUpper: ({ commit }, many) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`articulos/many?many=${many}&lng=${lng.toString()}`)
        .then(result => {
          const response = result.data
          commit('updateList', response)
          commit('updateTotal', 0)
        }).catch(error => {
          console.log(error)
        })
    },
    ApiManyCategories: ({ dispatch, commit }, many) => {
      commit('updateManyCategories', many)
      dispatch('changeCategory', -1)
    },
    apiSearch: ({
      commit,
      dispatch
    }, chunkName) => {
      commit('updateSearch', chunkName)
      dispatch('changeCategory', -1)
      // dispatch('apiPagination', 1)
    },
    cancelSearch: ({
      commit,
      dispatch
    }) => {
      commit('updateSearch', '')
      dispatch('apiPagination')
    },
    changeOrder: ({
      commit,
      dispatch
    }, order) => {
      commit('updateOrder', order)
      dispatch('apiPagination')
    },
    changeColumn: ({
      commit,
      dispatch
    }, column) => {
      commit('updateColumn', column)
      dispatch('apiPagination')
    },
    changeOrderAndColumn: ({
      commit,
      dispatch
    }, {
      column,
      order
    }) => {
      commit('updateColumn', column)
      commit('updateOrder', order)
      dispatch('apiPagination')
    },
    changeCategory: ({
      commit,
      dispatch
    }, category) => {
      if (category === '-1' || category === -1) {
        commit('updateCategory', null)
      } else {
        commit('updateManyCategories', [])
        commit('updateCategory', category)
      }
      commit('updateCheckPrice', false)
      commit('updateSub', null)
      commit('updateVariedad', null)
      dispatch('apiPagination', 1)
      dispatch('getPrices')
    },
    changeCategoryNoApi: ({
      commit,
      dispatch
    }, category) => {
      if (category === '-1' || category === -1) {
        commit('updateCategory', null)
      } else {
        commit('updateCategory', category)
        commit('updateManyCategories', [])
      }
      commit('updateCheckPrice', false)
      commit('updateSub', null)
      commit('updateVariedad', null)
      // dispatch('apiPagination', 1)
      // dispatch('getPrices')
    },
    changeSubCategory: ({
      commit,
      dispatch
    }, subCategory) => {
      commit('updateManyCategories', [])
      commit('updateCheckPrice', false)
      commit('updateSub', subCategory)
      commit('updateVariedad', null)
      dispatch('apiPagination', 1)
      dispatch('getPrices')
    },
    changeVariedad: ({
      commit,
      dispatch
    }, variedad) => {
      commit('updateManyCategories', [])
      commit('updateCheckPrice', false)
      commit('updateVariedad', variedad)
      dispatch('apiPagination', 1)
      dispatch('getPrices')
    },
    getPrices: ({
      commit,
      state
    }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      let url = 'tienda/precios?lng=' + lng
      url += state.category ? `&familia=${state.category}` : ''
      url += state.subcategory ? `&sub=${state.subcategory}` : ''
      url += state.variedad ? `&varia=${state.variedad}` : ''
      axiosStore.get(url).then(result => {
        const precios = result.data
        const uPrice = []
        uPrice.push(parseInt(precios.first.PrecioVenta))
        uPrice.push(parseInt(precios.last.PrecioVenta))
        commit('updatePrecios', uPrice)
        commit('updateCurrentFilterPrice', uPrice)
      }).catch(error => console.log(error))
    },
    changeCurrentPrice ({
      commit,
      dispatch
    }, {
      init = 0,
      end = 0
    }) {
      commit('updateCurrentFilterPrice', [init, end])
      dispatch('apiPagination', 1)
    },
    changePerPage: ({
      commit,
      dispatch
    }, perPage) => {
      commit('updatePerPage', perPage)
    },
    apiGaleria: ({ commit }, opciones) => {
      const variedad = opciones.variedad
      const sub = opciones.subfamilia
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      if (variedad) {
        axiosStore.get('one/gallery?lng=' + lng + '&variedad=' + variedad)
          .then(response => {
            commit('updateGallery', response.data)
          })
          .catch(error => {
            console.log(error)
          })
      } else if (sub) {
        axiosStore.get('one/gallery?lng=' + lng + '&sub=' + sub)
          .then(response => {
            commit('updateGallery', response.data)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    setFilterBodega: ({ commit }, filter) => {
      commit('updateFilterBodega', filter)
      commit('updateCurrentBodega', [])
    },
    setCurrentBodega: ({ commit, dispatch }, bodega) => {
      commit('updateCurrentBodega', bodega)
      dispatch('apiPagination', 1)
    },
    setCurrentMaridaje: ({ commit, dispatch }, maridaje) => {
      commit('updateCurrentMaridaje', maridaje)
      dispatch('apiPagination', 1)
    },
    setCurrentCountry: ({ commit, dispatch }, country) => {
      commit('updateCurrentPaises', country)
      dispatch('apiPagination', 1)
    }
  },
  getters: {
    getPage: state => state.page,
    getList: state => state.listProducts,
    getLoading: state => state.loading,
    getSearch: state => state.search,
    getCategory: state => state.category,
    getSub: state => state.subcategory,
    getVariante: state => state.variedad,
    getProduct: state => state.product,
    getPrice: state => state.precios,
    getMany: state => state.many,
    getGallery: state => state.galeria,
    getBodegas: state => state.bodegas.filter(item => item.includes(state.filterBodega.toUpperCase())),
    getCurrentBodega: state => state.currentBodega,
    getFilterBodega: state => state.filterBodega,
    getLetterBodega: state => state.letterBodega,
    getMaridaje: state => state.maridaje,
    getCurrentMaridaje: state => state.currentMaridaje,
    getPaises: state => state.paises,
    getCurrentPaises: state => state.currentPaises
  }

}

export default products
