import axios from 'axios'
import { ElMessage } from 'element-plus'

const language = () => {
  const lng = localStorage.getItem('lng')
  return !lng ? 1 : parseInt(lng)
}

let apiDev
if (process.env.VUE_APP_API) {
  apiDev = 'https://fiberapi.me/api/v2'
  // apiDev = 'https://api.winepalace.es/api/v2'
} else {
  apiDev = 'https://api.winepalace.es/api/v2'
  // apiDev = 'http://127.0.0.1:8000/api/v2'
}
const axiosStore = axios.create({
  baseURL: apiDev,
  timeout: 0
})

axiosStore.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.request) {
    console.log('Error request: ', error.request)
    if (error.request.status === 401) {
      const message = [
        'Problemas con las credenciales. Verifique los datos ingresados e inténtelo de nuevo',
        'Problemes amb les credencials. Verifiqui les dades ingressades i intenti-ho de nou',
        'Problems with credentials. Verify the data entered and try again.',
        'Problèmes d\'accréditation. Vérifiez les données saisies et réessayez.'
      ]
      ElMessage.error({
        showClose: false,
        message: message[language() - 1],
        duration: 6000
      })
      setTimeout(() => {
        console.clear()
      }, 500)
    }
    if (error.request.status === 402) {
      const message = [
        'No se ha validado el email',
        'No s\'ha validat l\'email',
        'Email has not been validated',
        'Le courriel n\'a pas été validé'
      ]
      ElMessage.error({
        showClose: false,
        message: message[language() - 1],
        duration: 6000
      })
      setTimeout(() => {
        console.clear()
      }, 500)
    }
    if (error.request.status === 422) {
      const messageCorreo = [
        'El correo electrónico ya existe',
        'El correu electrònic ja existeix',
        'E-mail already exists',
        'Le courriel existe déjà'
      ]
      const message = [
        'Problemas con las credenciales.',
        'Problemes amb les credencials.',
        'Problems with credentials.',
        'Problèmes d\'accréditation.'
      ]
      const mistake = JSON.parse(error.request.response).errors && JSON.parse(error.request.response).errors.email.length > 0 ? messageCorreo[language() - 1] : ''
      console.log(mistake)
      ElMessage.error({
        showClose: false,
        message: `${message[language() - 1]} ${mistake}`,
        duration: 6000
      })
      setTimeout(() => {
        console.clear()
      }, 500)
    }
  } else {
    console.log('Error response: ', error.message)
  }
  return Promise.reject(error)
})

export default axiosStore
