import axiosStore from '../../../Api/ApiConfig'

const somos = {
  namespaced: true,
  state: {
    iconsSize: 0,
    title: '',
    textoInicial: '',
    textoFinal: '',
    footerContent: [],
    itemsTexto: []
  },
  mutations: {
    updateTitle: (state, title) => { state.title = title },
    updateTextoInicial: (state, texto) => { state.textoInicial = texto },
    updateTextoFinal: (state, texto) => { state.textoFinal = texto },
    updateFooterContent: (state, content) => {
      if (Array.isArray(content)) {
        state.footerContent = content
      }
    },
    updateItemsTexto: (state, items) => {
      if (Array.isArray(items)) {
        state.itemsTexto = items
      }
    },
    updateIconSize: (state, size) => { state.iconsSize = size }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('quienes', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateTextoInicial', texto.textoInicial)
        commit('updateTextoFinal', texto.textoFinal)
        commit('updateFooterContent', texto.footerContent)
        commit('updateItemsTexto', texto.itemTexto)
        commit('updateTitle', texto.title)
        commit('updateIconSize', texto.iconsSize)
      }).catch(error => {
        console.log(error)
      })
    },
    changeTextoInicial: ({ commit }, texto) => { commit('updateTextoInicial', texto) },
    changeTextoFinal: ({ commit }, texto) => { commit('updateTextoFinal', texto) },
    changeFooterContent: ({ commit }, footer) => { commit('updateFooterContent', footer) },
    changeItemsTexto: ({ commit }, items) => { commit('updateItemsTexto', items) },
    changeTitle: ({ commit }, title) => { commit('updateTitle', title) },
    changeIconSize: ({ commit }, size) => { commit('updateIconSize', size) }
  },
  getters: {
    getTextoInicial: (state) => state.textoInicial,
    getTextoFinal: (state) => state.textoFinal,
    getFooter: (state) => state.footerContent,
    getItems: (state) => state.itemsTexto,
    getTitle: (state) => state.title,
    getIconSize: (state) => state.iconsSize
  }
}

export default somos
