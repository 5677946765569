import axiosStore from '@/Api/ApiConfig'

const voting = {
  namespaced: true,
  state: {
    listArticlesVoting: []
  },
  mutations: {
    updateArticlesList: (state, list) => {
      state.listArticlesVoting = list
    }
  },
  actions: {
    ApiGetList: ({ commit }) => {
      const predefinido = '23690,29229,29495,29595,29918,29225,29148,26346,29272,29822,29741,22222,5868,7491'
      axiosStore.get('articulos/many?many=' + predefinido).then(response => {
        commit('updateArticlesList', response.data)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getList: state => state.listArticlesVoting
  }
}

export default voting
