<template>
  <div class="search-glass" :class="search || focus ? 'two' : 'only'" @focusin="focus = true" @focusout="focus = false">
    <div class="lupa" @click="triggerSearch">
      <img :src="LupaImage" alt="lupa"/>
    </div>
    <div>
      <input id="buscadorWine" v-model="search" :placeholder="placeholder" @keyup.enter="triggerSearch"/>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'search-product',
  emits: ['change'],
  setup (_props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const search = ref('')
    const LupaImage = require('@/assets/img/search/lupa.svg')
    const language = computed(() => store.getters['Init/getLng'])
    const placeholder = computed(() => ['¿Qué estás buscando?', 'Què estàs buscant?', 'What are you looking for?', 'Que cherchez-vous ?'][language.value - 1])
    const focus = ref(false)
    const control = ref(true)
    const triggerSearch = () => {
      emit('change')
      console.log('emitido')
      if (route.name === 'Tienda') {
        store.dispatch('products/apiSearch', search.value)
      } else {
        router.push({ name: 'Tienda', query: { filter: search.value } })
        // window.location.href = 'tienda/?filter=' + search.value
      }
      control.value = false
      search.value = ''
    }
    // const focusSearch = () => {
    //   const miInput = document.querySelector('#buscadorWine')
    //   const campoDeEntrada = document.querySelector('.dfd-searchbox-input')
    //   if (campoDeEntrada) {
    //     if (campoDeEntrada !== document.activeElement) {
    //       console.log('ha entrado')
    //       miInput.removeEventListener('focus', focusSearch)
    //       miInput.blur()
    //       miInput.style.display = 'none'
    //       campoDeEntrada.focus()
    //     }
    //   }
    // }

    watch(() => search.value, current => {
      if (current === '' && control.value) {
        store.dispatch('products/cancelSearch')
      } else {
        control.value = true
      }
    })
    return {
      search,
      LupaImage,
      focus,
      triggerSearch,
      placeholder
    //  focusSearch
    }
  }
}
</script>

<style scoped>

</style>
