<template>
<div class="language-menu">
  <!--<ul>
    <li @click="setLang(option.id)" v-for="option in filterOptions" :key="option.id" :class="option.active ? 'active' : ''">
      <a href="#" :data-hover="option.title">
      {{ option.title }}
      </a>
    </li>
  </ul>-->
  <el-select v-model="languageSelect">
    <el-option v-for="option in filterOptions" :key="option.id" :value="option.id" :label="option.name">
        {{ option.name }}
    </el-option>
  </el-select>
  <div class="lupa-1200">
    <search />
  </div>
</div>
</template>

<script>
import { useStore } from 'vuex'
import search from '@/components/pagine/parts/search'
import { computed, inject, ref, watch } from 'vue'
export default {
  name: 'language-web',
  components: { search },
  setup () {
    const store = useStore()
    const emitter = inject('emitter')
    const listLng = computed(() => store.getters['Init/getAllLanguages'])
    const optionSelect = computed(() => store.getters['Init/getLng'])
    const setLang = (id) => {
      if (id !== optionSelect.value) {
        store.dispatch('Init/changeLng', id)
        emitter.emit('language')
      }
    }
    const filterOptions = computed(() => listLng.value.map(item => {
      return {
        active: item.id === optionSelect.value,
        title: item.select,
        id: item.id,
        name: item.title
      }
    }))
    const languageSelect = ref(optionSelect.value)
    watch(() => optionSelect.value, current => {
      languageSelect.value = current
    })
    watch(() => languageSelect.value, current => {
      setLang(current)
    })
    return { filterOptions, setLang, languageSelect }
  }
}
</script>
