import axiosStore from '@/Api/ApiConfig'

const eventos = {
  namespaced: true,
  state: {
    eventos: {
      per_page: 10,
      current_page: 1,
      data: []
    },
    table: {
      title: '',
      dia: '',
      hora: '',
      lugar: '',
      tema: '',
      plazas: '',
      descripcion: '',
      informacion: '',
      inscripcion: '',
      agotadas: '',
      pasada: '',
      volver: '',
      send: '',
      form: {
        title: '',
        nombre: '',
        email: '',
        telefono: '',
        cuantos: '',
        cancelar: ''
      },
      message: {
        title1: '',
        title2: '',
        title3: '',
        title4: ''
      }
    },
    allTiendas: [],
    tiendaActual: 0
  },
  mutations: {
    updateTable: (state, table) => { state.table = table },
    updateEventos: (state, eventos) => {
      state.eventos = eventos
    },
    updatePagination: (state, pagination) => {
      state.eventos.per_page = pagination.per_page ? pagination.per_page : state.eventos.per_page
      state.eventos.current_page = pagination.current_page ? pagination.current_page : state.eventos.current_page
    },
    updateTiendas: (state, tiendas) => {
      state.allTiendas = tiendas
    },
    updateCursoTienda: (state, cursos) => {
      state.tiendaActual = cursos
    }
  },
  actions: {
    apiTable: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('eventos/table?lng=' + lng).then(response => { commit('updateTable', response.data) }).catch(error => { console.log(error) })
    },
    apiEventos: ({ commit, state }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('eventos?lng=' + lng + '&per_page=' + state.eventos.per_page + '&page=' + state.eventos.current_page + '&tienda=' + state.tiendaActual).then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          const eventos = response.data.data
          eventos.forEach((evento, index) => {
            let cont = 0
            if (evento.asistente.length > 0) {
              evento.asistente.forEach(item => {
                cont += parseInt(item.Cantidad)
              })
            }
            eventos[index].asist = cont
            eventos[index].disponible = parseInt(eventos[index].Aforo) - cont
          })
          response.data.data = eventos
          response.data.per_page = parseInt(response.data.per_page)
          commit('updateEventos', response.data)
        } else {
          commit('updateEventos', [])
        }
      })
    },
    apiSendInscripcion ({ commit }, data) {
      axiosStore.get('eventos/mail', { params: data }).then(response => {
        console.log(response)
        commit()
      }).catch(error => {
        console.log(error)
      })
    },
    changePagination: ({ commit, dispatch }, pagination) => {
      commit('updatePagination', pagination)
      dispatch('apiEventos')
    },
    apiAllTiendas: ({ commit }) => {
      axiosStore.get('eventos/tiendas').then(response => { commit('updateTiendas', response.data) }).catch(error => { console.log(error) })
    },
    changeTienda: ({ commit }, data) => {
      commit('updateCursoTienda', data)
    }
  },
  getters: {
    getEventos: state => state.eventos.data,
    getPaginationEventos: state => state.eventos,
    getTable: state => state.table,
    getAllTiendas: state => state.allTiendas
  }

}

export default eventos
