import axiosStore from '../../../Api/ApiConfig'

const base = {
  namespaced: true,
  state: {
    Privacity: {
      title: '',
      content: ''
    },
    Conditions: {
      title: '',
      content: []
    }
  },
  mutations: {
    updatePrivacity: (state, privacidad) => {
      state.Privacity = privacidad
    },
    updateCondition: (state, condition) => { state.Conditions = condition }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('legal', { params: { lng: lng.toString() } }).then(response => {
        commit('updatePrivacity', response.data.privacity)
        commit('updateCondition', response.data.conditions)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getConditions: state => state.Conditions,
    getPrivacity: state => state.Privacity
  }

}

export default base
