export default function loadDoofinder () {
  const dfLayerOptions = {
    installationId: '90ff43e5-5c4d-4e64-ac9b-012cf9670d93',
    zone: 'eu1'
  };

  (function (l, a, y, e, r, s) {
    r = l.createElement(a); r.onload = e; r.async = 1; r.src = y
    s = l.getElementsByTagName(a)[0]; s.parentNode.insertBefore(r, s)
  })(document, 'script', 'https://cdn.doofinder.com/livelayer/1/js/loader.min.js', function () {
    // eslint-disable-next-line no-undef
    doofinderLoader.load(dfLayerOptions)
  })

  /*
  const dfLayerOptions = {
    installationId: '53991a50-9fe3-476b-87bc-70f77d413650',
    zone: 'eu1'
  };

  (function (l, a, y, e, r, s) {
    r = l.createElement(a); r.onload = e; r.async = 1; r.src = y
    s = l.getElementsByTagName(a)[0]; s.parentNode.insertBefore(r, s)
  })(document, 'script', 'https://cdn.doofinder.com/livelayer/1/js/loader.min.js', function () {
    // eslint-disable-next-line no-undef
    doofinderLoader.load(dfLayerOptions)
  })
  */
}
