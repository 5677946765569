<template>
  <el-badge :value="tCart" class="item">
    <div :class="tCart > 0 ? 'pointer' : ''" @click="modalCart()">
      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="retail-bag-1">
        <path data-name="layer2" fill="none" stroke-miterlimit="10" d="M4 14h56v48H4zm2 0V2h52v12"
              stroke-linejoin="round" stroke-linecap="round"></path>
        <path data-name="layer2" fill="none" stroke-miterlimit="10" d="M6.2 2.1L16 8 6 14M57.8 2.1L48 8l10 6"
              stroke-linejoin="round" stroke-linecap="round"></path>
        <path data-name="layer1" d="M42 24a10 10 0 0 1-20 0" fill="none" stroke-miterlimit="10" stroke-linejoin="round"
              stroke-linecap="round"></path>
      </svg> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="white" d="M432 928a48 48 0 1 1 0-96 48 48 0 0 1 0 96zm320 0a48 48 0 1 1 0-96 48 48 0 0 1 0 96zM96 128a32 32 0 0 1 0-64h160a32 32 0 0 1 31.36 25.728L320.64 256H928a32 32 0 0 1 31.296 38.72l-96 448A32 32 0 0 1 832 768H384a32 32 0 0 1-31.36-25.728L229.76 128H96zm314.24 576h395.904l82.304-384H333.44l76.8 384z"></path></svg>
    </div>
  </el-badge>
  <CartModal v-model:visible="cartVisible" />
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import CartModal from '@/components/pagine/cliente/CartModal'
import { ElMessage } from 'element-plus'

export default {
  name: 'symbol-cart',
  components: { CartModal },
  setup () {
    const store = useStore()
    const tCart = computed(() => store.getters['carrito/getCount'])
    const cartVisible = ref(false)
    const lang = computed(() => store.getters['Init/getLng'])
    const modalCart = () => {
      if (tCart.value > 0) {
        cartVisible.value = true
      } else {
        const cartEmpty = computed(() => ['El carrito está vacío', 'El carret està buit', 'Cart is empty', 'Le chariot est vide'][lang.value - 1])
        ElMessage.success(cartEmpty.value)
      }
    }
    onMounted(() => {
      store.dispatch('storeMenu/apiMenu')
    })
    return {
      tCart,
      cartVisible,
      modalCart
    }
  }
}
</script>

<style scoped>

</style>
