import axiosStore from '@/Api/ApiConfig'

const footer = {
  namespaced: true,
  state: {
    options: [],
    social: [
      {
        title: 'Facebook',
        url: 'https://www.facebook.com/pages/Wine-Palace/107260436010444?ref=tn_tnmn',
        icon: require('@/assets/img/social/facebook.png'),
        active: true
      },
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/wine_palace/',
        icon: require('@/assets/img/social/instagram.png'),
        active: true
      },
      {
        title: 'Twitter',
        url: 'https://twitter.com/@winepalace_es',
        icon: require('@/assets/img/social/twitter.png'),
        active: true
      },
      {
        title: 'Youtube',
        url: 'https://www.youtube.com/c/winepalace',
        icon: require('@/assets/img/social/youtube.png'),
        active: true
      }
    ]
  },
  mutations: {
    updateOptions: (state, options) => { state.options = options },
    updateSocial: (state, social) => { state.social = social },
    updateMenu: (state, menu) => { state.options = menu }
  },
  actions: {
    getFooterMenu: ({ commit }) => {
      const lngTmp = localStorage.getItem('lng')
      const lng = !lngTmp ? 1 : parseInt(lngTmp)
      axiosStore.get('menus/footer', { params: { lng: lng.toString() } }).then(response => {
        commit('updateMenu', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    changeOptions: ({ commit }, options) => { commit('updateOptions', options) },
    changeSocial: ({ commit }, social) => { commit('updateSocial', social) }
  },
  getters: {
    getSocial: (state) => state.social,
    getOptions: (state) => state.options.filter(item => item.active)
  }

}

export default footer
