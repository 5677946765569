import axiosStore from '@/Api/ApiConfig'

const storeMenu = {
  namespaced: true,
  state: {
    options: [],
    translate: {
      fav: {
        add: '',
        remove: ''
      },
      msg: {
        button: {},
        table: {}
      },
      one: {},
      filter: {},
      order: {}
    }
  },
  mutations: {
    updateMenu: (state, options) => {
      state.options = options
    },
    updateTranslate: (state, content) => { state.translate = content }
  },
  actions: {
    changeMenu: ({ commit }, options) => {
      commit('updateMenu', options)
    },
    apiMenu: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get(`/tienda/menu?lng=${lng.toString()}`)
        .then(result => {
          commit('updateMenu', result.data)
        }).catch(error => {
        // commit('updateLoading', false)
          console.log(error)
        })
    },
    ApiContent: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('tienda/content?lng=' + lng).then(response => { commit('updateTranslate', response.data) }).catch(error => { console.log(error) })
    }
  },
  getters: {
    getMenu: (state) => state.options,
    getContent: state => state.translate
  }

}

export default storeMenu
