import axiosStore from '@/Api/ApiConfig'

const redsys = {
  namespaced: true,
  state: {
    button: ''
  },
  mutations: {
    updateButton: (state, button) => {
      state.button = button
    }
    // updateButtonBizum: (state, button) => {
    //   state.buttonBizum = button
    // }
  },
  actions: {
    ApiRedSys: ({ commit }, amount) => {
      axiosStore.get('redsys/pay', { params: amount })
        .then(response => {
          commit('updateButton', response.data.form)
          // commit('updateButtonBizum', response.data.formBizum)
          localStorage.setItem('invoice', response.data.invoice)
        })
        .catch(error => {
          console.log(error)
        })
    },
    payRedSys: async ({ _commit }, data) => {
      const invoice = localStorage.getItem('invoice')
      /*  if (!invoice) {
        window.location.reload()
        return
      } */
      data.invoice = invoice

      localStorage.removeItem('redsys')
      // localStorage.removeItem('tienda')
      localStorage.removeItem('invoice')
      axiosStore.get('redsys/pago', { params: data })
        .then(() => true).catch(() => true)
    },
    recovery: ({ _commit }, data) => {
      data.invoice = localStorage.getItem('invoice')
      axiosStore.post('redsys/notification2', { token: data.token })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  getters: {
    getButton: state => state.button
    // getButtonBizum: state => state.buttonBizum
  }

}

export default redsys
