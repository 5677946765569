import axiosStore from '@/Api/ApiConfig'

const descuento = {
  namespaced: true,
  state: {
    descuentos: []
  },
  mutations: {
    updateDescuentos: (state, descuentos) => { state.descuentos = descuentos }
  },
  actions: {
    apiInit: ({ commit }) => {
      axiosStore.get('/tienda/descuentos').then(response => { commit('updateDescuentos', response.data) }).catch(error => { console.log(error) })
    }
  },
  getters: {
    getAll: state => state.descuentos
  }

}

export default descuento
