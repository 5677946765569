<template>
  <modal :visible="localVisible" v-if="$route.path !== '/cart'" class="antiMarginModal">
    <template #content>
      <el-table :data="carrito" style="width: 100%" stripe @row-click="rowClick">
        <el-table-column :label="titles.msg.table.imagen" width="180">
          <template #default="scope">
            <div class="listImage">
              <img :src="scope.row.articulo ? scope.row.articulo.image : ''" :alt="scope.row.articulo ? scope.row.articulo.Nombre : ''" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="titles.msg.table.nombre" >
          <template #default="scope">
            {{scope.row.articulo ? scope.row.articulo.Nombre : ''}}
          </template>
        </el-table-column>
        <el-table-column :label="titles.msg.table.cantidad" width="100">
          <template #default="scope">
            {{scope.row.Cantidad}}
          </template>
        </el-table-column>
        <el-table-column :label="preciounitario" width="200">
          <template #default="scope">
            {{defaultPrice(scope.row.articulo)}} €
          </template>
        </el-table-column>
        <el-table-column :label="titles.msg.table.importe" width="200">
          <template #default="scope">
            {{parseFloat(scope.row.Cantidad * scope.row.PrecioVenta).toFixed(2)}} €
          </template>
        </el-table-column>
        <el-table-column :label="titles.msg.table.eliminar" width="100">
          <template #default>
            <div style="width: 21px;margin: auto; color: red;margin-left: 17px;" class="pointer">
              <Delete-icon />
            </div>
          </template>
        </el-table-column>
    </el-table>
    <el-table :data="subCalc" :show-header="false" stripe class="cart">
              <el-table-column>
                <template #default="scope">
                  <div class="subtotalModal">
                    <span style="display: inline-block;">Subtotal</span>
                    <span style="display: inline-block;" class="subtotalModalPreu">{{ scope.row.value }} €</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div style="float:right;margin-top:15px" class="mensajeDescuentos">{{ descuentoss }}</div>
    </template>
    <template #footer>
      <div class="button-group">
      <button @click="goCart()">{{cart}}</button>
      <button  @click="localVisible = false">{{close}}</button>
      </div>
    </template>
  </modal>
</template>

<script>
import modal from '@/components/individual/modal'
import removeToCart from '@/assets/js/removeToCart'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted, watch, inject } from 'vue'
// import { Close } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  name: 'CartModal',
  components: { modal/* , Close */ },
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['update:visible'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const emitter = inject('emitter')
    const lang = computed(() => store.getters['Init/getLng'])
    const cart = computed(() => ['Ir al carrito', 'Anar a la cistella', 'Go to cart', 'Aller au panier'][lang.value - 1])
    const close = computed(() => ['Cerrar', 'Tancar', 'Close', 'Fermer'][lang.value - 1])
    const descuentoss = computed(() => ['En el siguiente paso encontrara las promociones y descuentos aplicados', 'Al següent pas trobareu les promocions i descomptes aplicats', 'In the next step you will find the promotions and discounts applied', 'Dans l\'étape suivante, vous trouverez les promotions et réductions appliquées'][lang.value - 1])
    const titles = computed(() => store.getters['storeMenu/getContent'])
    const preciounitario = computed(() => ['Precio unitario', 'Preu unitari', 'Unit price', 'Prix unitaire'][lang.value - 1])
    const localVisible = computed({
      get: () => props.visible,
      set: (value) => { emit('update:visible', value) }
    })
    const carrito = computed(() => store.getters['carrito/getArticles'])
    const carritoSum = computed(() => store.getters['carrito/getAll'])
    const rowClick = (row, column) => {
      if (column.label === titles.value.msg.table.eliminar) {
        ElMessageBox.confirm(
          titles.value.msg.qeliminar,
          titles.value.msg.button.cuidado,
          {
            confirmButtonText: titles.value.msg.button.eliminar,
            cancelButtonText: titles.value.msg.button.cancelar,
            type: 'warning'
          }
        )
          .then(() => {
            ElMessage({
              type: 'success',
              message: titles.value.msg.eliminar
            })
            store.dispatch('carrito/cartChange', removeToCart(carritoSum.value, row.articulo.Id))
            localVisible.value = true
            if (carritoSum.value.carritolineas.length <= 0) {
              localVisible.value = false
            }
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: titles.value.msg.noeliminar
            })
          })
      } else {
        if (route.path.includes('/tienda/')) {
          window.location.href = '/tienda/' + row.articulo.categoria + '/' + row.articulo.slug
        } else {
          router.push({ path: '/tienda/' + row.articulo.categoria + '/' + row.articulo.slug })
        }
      }
    }
    const goCart = () => { window.location.href = '/cart' }
    const fechaHoy = new Date()
    const defaultPrice = (product) => {
      let price = '0.0'
      let ofertaValida = false
      let ofertaActiva = 0
      if (product && product.website) {
        if (product.website.length > 0) {
          for (let i = 0; product.website[0].oferta.length > i; i++) {
            if (product.website[0].oferta[i] && fechaDB(product.website[0].oferta[i].FechaIni) <= formatFecha(fechaHoy) && fechaDB(product.website[0].oferta[i].FechaFin) >= formatFecha(fechaHoy)) {
              ofertaValida = true
              ofertaActiva = product.website[0].oferta[i]
              break
            }
            // eslint-disable-next-line no-constant-condition
          }
          if (ofertaValida) {
            price = parseFloat(ofertaActiva.Precio).toFixed(2).toString()
          } else {
            price = parseFloat(product.website[0].PrecioVenta).toFixed(2).toString()
          }
        }
      }
      return price
    }
    const formatFecha = (fecha) => {
      if (!fecha) {
        fecha = new Date()
      }
      const date = fecha.getDate()
      const mes = fecha.getMonth()
      const any = fecha.getFullYear()
      // Crear un nuevo objeto Date con los valores obtenidos
      const nuevoFecha = new Date(any, mes, date)
      return nuevoFecha
    }

    const fechaDB = (fecha) => {
      const date = fecha.split(' ')[0].split('-')
      const any = date[0]
      const mes = date[1]
      const dia = date[2]
      const nuevoFecha = new Date(any, mes - 1, dia)
      return nuevoFecha
    }
    // subcalc
    const subCalc = computed(() => {
      // const total = parseFloat(carritoSum.value.Total)
      // const totalLibre = carritoSum.value.carritolineas.reduce(function (accumulator, currentValue) {
      //   return parseFloat(accumulator) + parseFloat(currentValue.Importe)
      // }, 0)
      const totalLibre = carritoSum.value.carritolineas.reduce(function (accumulator, currentValue) {
        return parseFloat(accumulator) + parseFloat(currentValue.Cantidad * currentValue.PrecioVenta)
      }, 0)
      const response = [
        {
          value: parseFloat(totalLibre).toFixed(2)
        }
      ]
      return response
    })
    // subcalc
    onMounted(() => {
      store.dispatch('carrito/apiCart')
      store.dispatch('storeMenu/ApiContent')
      emitter.on('language', () => {
        store.dispatch('storeMenu/ApiContent')
      })
    })
    watch(() => carrito.value, current => {
      // store.dispatch('products/ApiManyProducts', current)
    })
    return {
      carrito,
      localVisible,
      rowClick,
      defaultPrice,
      goCart,
      cart,
      close,
      titles,
      preciounitario,
      subCalc,
      formatFecha,
      fechaDB,
      descuentoss
    }
  }
}
</script>

<style scoped>

</style>
