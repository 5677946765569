import { nanoid } from 'nanoid'
import axiosStore from '@/Api/ApiConfig'
import { ElMessage } from 'element-plus'

const carrito = {
  namespaced: true,
  state: {
    carrito: {},
    cupon: {},
    cuponStatus: false
  },
  mutations: {
    updateCupon: (state, cupon) => { state.cupon = cupon },
    updateCuponStatus: (state, cupon) => { state.cuponStatus = cupon },
    updateCart: (state, cart) => {
      state.carrito = cart
    },
    removeCart: (state, id) => {
      const original = Array.from(state.carrito)
      state.carrito = []
      original.forEach(item => {
        if (item.Id !== id) {
          state.carrito.push(item)
        }
      })
    },
    addCart: (state, item) => {
      const search = state.carrito.findIndex(el => el.Id === item.Id)
      if (search > -1) {
        state.carrito[search].cant++
      } else {
        item.cant = 1
        state.carrito.push(item)
      }
    }
  },
  actions: {
    apiCart: ({ commit }) => {
      const token = tokenCart()
      axiosStore.get('carrito?token=' + token).then(response => {
        commit('updateCart', response.data)
      }).catch(error => console.log(error))
    },
    cartAdd: ({ commit }, articulo) => {
      const token = tokenCart()
      axiosStore.get('carrito/add?token=' + token).then(response => {
      }).catch(error => console.log(error))
    },
    cartRemove: ({ commit }, articulo) => {
      const token = tokenCart()
      axiosStore('carrito/remove?token=' + token).then(response => {
        console.log(response.data)
      }).then(error => console.log(error.data))
    },
    cartChange: ({ dispatch }, cart) => {
      const token = tokenCart()
      const limit = cart.carritolineas.length
      for (let i = 0; i < limit; i++) {
        delete cart.carritolineas[i].articulo
      }
      axiosStore.get(`carrito/update?token=${token}&articulos=${JSON.stringify(cart)}`).then(() => dispatch('apiCart')).catch(error => { console.log(error) })
    },
    ApiCupon: ({ commit }, cupon) => {
      const cookie = localStorage.getItem('tienda')
      axiosStore.get('cupon?cupon=' + cupon + '&token=' + cookie)
        .then(response => {
          commit('updateCupon', response.data)
          commit('updateCuponStatus', true)
        })
        .catch(error => {
          if (error.request) {
            ElMessage.error(error.request.responseText)
          } else {
            console.log('Estatus no pillado')
          }
        })
    },
    removeCupon: ({ commit }) => {
      commit('updateCupon', {})
      commit('updateCuponStatus', false)
    },
    pedidoToCart: ({ dispatch }, pedido) => {
      const token = tokenCart()
      axiosStore.get('pedidoToCart?pedido=' + pedido + '&token=' + token)
        .then(response => {
          localStorage.removeItem('userCookie')
          localStorage.setItem('userCookie', response.data)
          setTimeout(() => {
            dispatch('apiCart')
            document.location.href = 'cart'
          }, 500)
        })
        .catch(error => { console.log(error) })
    }
  },
  getters: {
    getAll: state => state.carrito,
    getArticles: state => state.carrito.carritolineas || [],
    getCount: state => state.carrito.NArticulos ? parseInt(state.carrito.NArticulos) : 0,
    getCupon: state => state.cupon,
    getCuponStatus: state => state.cuponStatus
  }

}

const tokenCart = () => {
  let storage = localStorage.getItem('userCookie')
  if (!storage) {
    const token = nanoid()
    localStorage.setItem('userCookie', token)
    storage = token
  }
  return storage
}

export default carrito
