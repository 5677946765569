import apiConfig from '@/Api/ApiConfig'

const banners = {
  namespaced: true,
  state: {
    main: '',
    slider: []
  },
  mutations: {
    updateMain: (state, banner) => { state.main = banner },
    updateSlider: (state, slider) => { state.slider = slider }
  },
  actions: {
    searchMainBanner: ({ commit }) => {
      apiConfig.get('banners').then(response => { commit('updateMain', response.data) }).catch(error => { console.log(error) })
    },
    searchSlider: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      apiConfig.get('sliders?lng=' + lng).then(response => { commit('updateSlider', response.data) }).catch(error => { console.log(error) })
    }
  },
  getters: {
    getMainBanner: state => state.main,
    getSliders: state => state.slider
  }

}

export default banners
