<template>
<div class="advertencia" id="aviso">
Debe ser mayor de edad para compra en esta web
  <div class="close pointer" @click="down()">X</div>
</div>
</template>

<script>
import { onMounted } from 'vue'
import { nanoid } from 'nanoid'

export default {
  name: 'advertencia-sample',
  setup () {
    const down = () => {
      sessionStorage.age = nanoid()
      const element = document.getElementById('aviso')
      element.classList.add('downWarning')
      element.classList.remove('upWarning')
      setTimeout(() => {
        element.classList.remove('downWarning')
      }, 1500)
    }
    onMounted(() => {
      // const session = sessionStorage.age || null

      /* if (!session) {
        const element = document.getElementById('aviso')
        element.classList.add('upWarning')
      } */
    })
    return { down }
  }
}
</script>

<style scoped>

</style>
