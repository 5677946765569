import axiosStore from '@/Api/ApiConfig'

const estamos = {
  namespaced: true,
  state: {
    tiendas: []
  },
  mutations: {
    updateTiendas: (state, tiendas) => { state.tiendas = tiendas }
  },
  actions: {
    apiTiendas: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('tienda', { params: { lng: lng.toString() } }).then(response => {
        if (response.data.length > 0) {
          const tienda = response.data.map(item => {
            return {
              ...item,
              window: infoWinFormat(item)
            }
          })
          commit('updateTiendas', tienda)
        }
      }).catch(error => console.log(error))
    }
  },
  getters: {
    getTiendas: state => state.tiendas,
    getTiendaId: state => id => state.tiendas.find(item => item.Id === parseInt(id))
  }

}

const infoWinFormat = (item) => {
  let horarios = ''
  item.horario.forEach(element => {
    horarios += '<div><i class="far fa-clock"></i> ' + element.Texto + '</div>'
  })
  return '<div class="infoWin"><h2>' +
    item.Nombre + '</h2>' +
    '<div class="infoWinContent">' +
    '<div><em class="text-dark-negre">' + item.Direccion + '</em>' +
    '<em>, C.P.: ' + item.CodigoPostal + '</em>' +
    '<em>, ' + item.Poblacion + '</em>' +
    '<em>, ' + item.Provincia + '</em></div>' +
    '<div><i class="fas fa-phone"></i><em>  <a href="tel:' + item.Telefono + '">' + item.Telefono + '</a></em></div>' +
    '<div><i class="fas fa-envelope"></i><em>  <a href="mailto:' + item.Email + '"> ' + item.Email + '</a></em></div>' +
    horarios +
    '</div>' +
    '<div class="enlace-mapa">' +
    '<a href="tiendas/' + item.Id + '/' + slugify(item.Nombre) + '">Info</a>' +
    '</div>'
}
const slugify = (text) => {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export default estamos
