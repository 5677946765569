import axiosStore from '@/Api/ApiConfig'

const favorites = {
  namespaced: true,
  state: {
    products: []
  },
  mutations: {
    updateProducts: (state, products) => {
      state.products = products
    }
  },
  actions: {
    ApiFavorites: ({ commit }) => {
      const cookies = localStorage.getItem('userCookie')
      axiosStore.get(`tienda/favorites?cookie=${cookies}`).then(response => {
        commit('updateProducts', response.data)
      }).catch(error => console.log(error))
    },
    ApiAddFavorites: ({ dispatch }, products) => {
      const cookies = localStorage.getItem('userCookie')
      axiosStore.get(`tienda/favorite/add?cookie=${cookies}&products=${products}`).then(
        response => {
          dispatch('ApiFavorites')
        }
      ).catch(error => { console.log(error) })
    },
    AddFavorite: ({
      state,
      dispatch
    }, id) => {
      let internal = state.products
      const index = internal.find(item => parseInt(item) === parseInt(id))
      if (index === undefined) {
        internal.push(id)
      } else {
        internal = internal.filter(item => parseInt(item) !== parseInt(id))
      }
      console.log('index: ', index, internal)
      dispatch('ApiAddFavorites', internal)
    }
  },
  getters: {
    getAll: state => state.products
  }

}

export default favorites
