<template>
  <div class="menu_superior">
    <menu-superior-burger ref="burger"/>
    <div class="logo-wine">
      <label class="logo" @click="home()"> <img :src="logo" alt="logo"/></label>
    </div>
    <div class="min">
      <ul class="options-upper" id="options-upper">
        <li v-for="option in listOptionsComputed" :key="option.title" @click="changeCurrent(option)">
          <a href="#" :data-hover="option.title">{{ option.title }}</a>
          <!--<router-link :to="{path: option.url}" >{{option.title}}</router-link>-->
        </li>
      </ul>
    </div>
    <div class="lupa-full">
      <search @change="drawneroff()"/>
    </div>
    <language/>
      <!-- <el-icon size="600"><ShoppingCart /></el-icon> -->
      <div class="contentCartUser">
      <a href="/cliente"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user" viewBox="0 0 24 24">
  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
  <circle cx="12" cy="7" r="4"></circle>
</svg></a>
     <symbol-cart ><el-icon size="600"><ShoppingCart /></el-icon></symbol-cart>
      </div>
  </div>
  <menu-superior-tablet ref="superior"/>
  <!--<menu-uvas v-if="current !== '/'"/>-->
</template>

<script>
import { onMounted, computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute /* , useRouter */ } from 'vue-router'
import menuSuperiorTablet from '@/components/pagine/parts/menu-superior-tablet'
import search from '@/components/pagine/parts/search'
import language from '@/components/pagine/parts/language'
import menuSuperiorBurger from '@/components/pagine/parts/menu-superior-burger'
import symbolCart from '@/components/pagine/parts/symbol-cart'
import loadDoofinder from '@/assets/js/doofinder'
// import menuUvas from '@/components/pagine/parts/menu-uvas'
import { ShoppingCart/* , CirclePlus, Remove, ArrowDownBold, ArrowUpBold, ArrowRightBold, FullScreen, Select, ShoppingCart, InfoFilled */ } from '@element-plus/icons-vue'

const logoImg = require('@/assets/img/logo.png')
export default {
  name: 'menu-superior',
  components: {
    search,
    language,
    menuSuperiorBurger,
    symbolCart,
    menuSuperiorTablet,
    ShoppingCart /*,
    menuUvas */
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const emitter = inject('emitter')
    const superior = ref('')
    const burger = ref('')
    // const router = useRouter()
    const logo = logoImg
    const current = computed(() => route.fullPath)
    const listOptionsComputed = computed(() => {
      return store.getters['menu/menuList'].map((item, index) => {
        return {
          ...item,
          index
        }
      })
    })
    const selectMenu = () => {
      const position = listOptionsComputed.value.findIndex(item => item.url === current.value)
      if (position > -1) {
        const clearPosition = position || 0
        document.getElementById('options-upper').children[clearPosition].classList.add('current')
      }
    }
    const clearCurrent = () => {
      const element = document.getElementById('options-upper')
      const children = element.children
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        child.classList.remove('current')
      }
    }
    const changeCurrent = ({
      index,
      url
    }) => {
      const ClearIndex = index || 0
      store.dispatch('menu/changeMenuOption', ClearIndex)
      clearCurrent()
      selectMenu()
      if (url && url !== current.value) {
        window.location.href = url
      }
      //  :href="option.url===current ? '#' : option.url"
    }
    const home = () => {
      window.location.href = '/'
    }
    const drawneroff = () => {
      if (superior.value.status || burger.value.status) {
        superior.value.togle()
        burger.value.togle()
      }
    }
    onMounted(() => {
      const script = document.createElement('script')
      script.src = 'https://kit.fontawesome.com/2ce52a83f2.js'
      script.crossOrigin = 'anonymous'
      document.body.appendChild(script)
      emitter.on('language', () => {
        setTimeout(() => {
          store.dispatch('menu/ApiGet')
        }, 1000)
      })
      setTimeout(() => {
        clearCurrent()
        selectMenu()
      }, 1000)
      loadDoofinder()
    })

    return {
      listOptionsComputed,
      changeCurrent,
      current,
      home,
      logo,
      superior,
      burger,
      drawneroff
    }
  }
}
</script>

<style scoped>

</style>
