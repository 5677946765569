import { nanoid } from 'nanoid'

const Init = {
  namespaced: true,
  state: {
    Languages: [
      {
        title: 'ES', culture: 'es', id: 1, select: 'ES'
      },
      {
        title: 'CA', culture: 'ca', id: 2, select: 'CA'
      },
      {
        title: 'EN', culture: 'en', id: 3, select: 'EN'
      },
      {
        title: 'FR', culture: 'fr', id: 4, select: 'FR'
      }
    ],
    lng: 1,
    cookies: null
  },
  mutations: {
    updateLng: (state, lng) => {
      if (lng < 1) { lng = 1 }
      if (lng > 4) { lng = 4 }
      state.lng = lng
      localStorage.setItem('lng', lng.toString())
    },
    updateCookie: (state, cookie) => {
      state.cookies = cookie
    }
  },
  actions: {
    changeLng: ({ commit }, lng) => { commit('updateLng', lng) },
    initLng: ({ commit, state }) => {
      const lng = localStorage.getItem('lng')
      if (!lng) {
        const ln = navigator.language
        if (ln) {
          const culture = ln.split('-')
          if (Array.isArray(culture) && culture.length > 1) {
            const search = state.Languages.find(item => item.culture === culture[0])
            if (search) {
              localStorage.setItem('lng', search.id.toString())
              commit('updateLng', search.id)
            } else {
              localStorage.setItem('lng', '1')
              commit('updateLng', 1)
            }
          } else {
            localStorage.setItem('lng', '1')
            commit('updateLng', 1)
          }
        } else {
          localStorage.setItem('lng', '1')
          commit('updateLng', 1)
        }
      } else {
        commit('updateLng', parseInt(lng))
      }
      let cookie = localStorage.getItem('userCookie')
      if (!cookie) {
        cookie = nanoid()
        localStorage.setItem('userCookie', cookie)
      }
      commit('updateCookie', cookie)
    }
  },
  getters: {
    getLng: state => state.lng,
    getLanguage: state => state.Languages.filter(item => item.id === state.lng),
    getLanguageIso: state => state.Languages.find(item => item.id === state.lng).culture,
    getAllLanguages: state => state.Languages
  }

}

export default Init
