import axiosStore from '@/Api/ApiConfig'

const uvas = {
  namespaced: true,
  state: {
    optionsMenuActive: 0,
    optionsMenuList: [],
    optionsHomeMenu: []
  },
  mutations: {
    updateOptionMenu: (state, option) => {
      if (option >= state.optionsMenuList.length) {
        option = state.optionsMenuList.length - 1
      }
      if (option < 0) {
        option = 0
      }
      state.optionsMenuActive = option
    },
    updateMenuList: (state, menu) => {
      if (Array.isArray(menu)) {
        state.optionsMenuList = menu
        const newMenu = Array.from(menu)
        const tmp = newMenu[0]
        newMenu[0] = newMenu[5]
        newMenu[5] = tmp
        state.optionsHomeMenu = newMenu
      }
      if (state.optionsMenuActive >= state.optionsMenuList.length) {
        state.optionsMenuActive = state.optionsMenuList.length - 1
      }
    }
  },
  actions: {
    ApiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('menus/uvas', { params: { lng: lng.toString() } }).then(response => {
        commit('updateMenuList', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    changeMenuOption: ({ commit }, option) => {
      commit('updateOptionMenu', option)
    },
    changeMenuList: ({ commit }, menu) => {
      commit('updateMenuList', menu)
    }
  },
  getters: {
    optionActive: (state) => state.optionsMenuActive,
    menuList: (state) => state.optionsMenuList.filter(item => item.active),
    menuListHome: (state) => state.optionsHomeMenu.filter(item => item.active)
  }

}

export default uvas
