import axiosStore from '@/Api/ApiConfig'

const menu = {
  namespaced: true,
  state: {
    optionsMenuActive: 0,
    optionsMenuList: [],
    drawer: false,
    drawerFiltro: false,
    drawerOrder: false
  },
  mutations: {
    updateOptionMenu: (state, option) => {
      if (option >= state.optionsMenuList.length) {
        option = state.optionsMenuList.length - 1
      }
      if (option < 0) {
        option = 0
      }
      state.optionsMenuActive = option
    },
    updateMenuList: (state, menu) => {
      if (Array.isArray(menu)) {
        state.optionsMenuList = menu
      }
      if (state.optionsMenuActive >= state.optionsMenuList.length) {
        state.optionsMenuActive = state.optionsMenuList.length - 1
      }
    },
    updateDrawer: (state, drawer) => {
      state.drawer = drawer
      if (drawer) {
        state.drawerFiltro = false
        state.drawerOrder = false
      }
    },
    updateDrawerFiltro: (state, drawer) => {
      state.drawerFiltro = drawer
      if (drawer) {
        state.drawer = false
        state.drawerOrder = false
      }
    },
    updateDrawerOrder: (state, drawer) => {
      state.drawerOrder = drawer
      if (drawer) {
        state.drawerFiltro = false
        state.drawer = false
      }
    }
  },
  actions: {
    ApiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('menus/upper', { params: { lng: lng.toString() } }).then(response => {
        commit('updateMenuList', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    changeMenuOption: ({ commit }, option) => {
      commit('updateOptionMenu', option)
    },
    changeMenuList: ({ commit }, menu) => {
      commit('updateMenuList', menu)
    },
    setDrawer ({ commit }, drawer) {
      commit('updateDrawer', drawer)
    },
    setDrawerFiltro ({ commit }, drawer) {
      commit('updateDrawerFiltro', drawer)
    },
    setDrawerOrder ({ commit }, drawer) {
      commit('updateDrawerOrder', drawer)
    }
  },
  getters: {
    optionActive: (state) => state.optionsMenuActive,
    menuList: (state) => state.optionsMenuList.filter(item => item.active),
    getDrawer: state => state.drawer,
    getDrawerFiltro: state => state.drawerFiltro,
    getDrawerOrder: state => state.drawerOrder
  }

}

export default menu
