<template>
  <div class="container-burger">
    <svg viewBox="0 0 60 50" width="30" height="30" @click="togle" style="cursor: pointer">
    <line x1 = "0" y1 = "0" x2 = "60" :y2 = "position" stroke = "white" stroke-width = "3"/>
    <line x1 = "0" y1 = "20" x2 = "60" y2 = "20" stroke = "white" stroke-width = "3" :style="vision"/>
    <line x1 = "0" y1 = "40" x2 = "60" :y2 = "40 - position" stroke = "white" stroke-width = "3"/>
  </svg>
    <!-- <div  class="menu-burger" :style="menuVision">
      <ul class="options-burger" id="options-burger">
        <li v-for="option in listOptionsComputed" :key="option.title" @click="changeCurrent(option)">
          <a href="#" :data-hover="option.title">{{option.title}}</a>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'menu-superior-burger',
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const position = ref(0)
    const status = computed(() => store.getters['menu/getDrawer'])
    const vision = computed(() => 'opacity: ' + ((40 - position.value) * 2.5 / 100))
    // const menuVision = computed(() => 'opacity: ' + (position.value * 2.5 / 100) + '; z-index: ' + ((position.value * 3) - 10))
    const menuVision = computed(() => 'opacity: 0')
    const current = computed(() => route.fullPath)
    const togle = () => {
      // eslint-disable-next-line no-unreachable
      /* if (!status.value) {
        // position.value = 0
        for (let i = 0; i < 40; i += 2) {
          setTimeout(() => {
            position.value += 2
            if (position.value > 40) {
              position.value = 40
            }
          }, 10 * i)
        }
      } else {
        // position.value = 60
        for (let i = 0; i < 60; i += 2) {
          setTimeout(() => {
            position.value -= 2
            if (position.value < 0) {
              position.value = 0
            }
          }, 10 * i)
        }
      } */
      console.log('status', status.value)
      store.dispatch('menu/setDrawer', !status.value)
      // status.value = !status.value
    }
    const listOptionsComputed = computed(() => {
      return store.getters['menu/menuList'].map((item, index) => {
        return {
          ...item,
          index
        }
      })
    })
    const selectMenu = () => {
      const Lposition = store.getters['menu/optionActive']
      const clearPosition = Lposition || 0
      document.getElementById('options-burger').children[clearPosition].classList.add('current')
    }
    const clearCurrent = () => {
      const element = document.getElementById('options-burger')
      const children = element.children
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        child.classList.remove('current')
      }
    }
    const changeCurrent = ({ index, url }) => {
      // status.value = true
      // store.dispatch('menu/setDrawer', true)
      togle()
      const ClearIndex = index || 0
      store.dispatch('menu/changeMenuOption', ClearIndex)
      clearCurrent()
      selectMenu()
      setTimeout(() => {
        if (url && url !== current.value) {
          router.push(url)
        }
      }, 50)
      //  :href="option.url===current ? '#' : option.url"
    }
    watch(() => status.value, current => {
      /* if (current && position.value > 0) {
        togle()
      }
      if (!current && position.value < 40) {
        togle()
      } */
    })
    return {
      position,
      togle,
      vision,
      status,
      menuVision,
      listOptionsComputed,
      changeCurrent
    }
  }
}
</script>

<style scoped>

</style>
