import axiosStore from '@/Api/ApiConfig'
import { ElMessage } from 'element-plus'

const preguntas = {
  namespaced: true,
  state: {
    loading: false,
    questions: [],
    pagination: {
      per_page: 10,
      current_page: 1
    },
    searchText: ''
  },
  mutations: {
    updateQuestions: (state, questions) => {
      state.questions = questions
    },
    updateLoading: (state, loading) => { state.loading = loading },
    updatePagination: (state, pagination) => { state.pagination = pagination },
    updateSize: (state, size) => { state.pagination.per_page = size },
    updateCurrent: (state, current) => { state.pagination.current_page = current },
    updateSearchText: (state, searchText) => { state.searchText = searchText }
  },
  actions: {
    apiPreguntas: ({ commit, state }, options = { order: 'DESC', column: 'Fecha' }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      if (!options.paginate) {
        options.paginate = state.pagination.per_page
      }
      let url = `somelier?paginate=${options.paginate}&order=${options.order}&column=${options.column}&lng=${lng.toString()}&page=${state.pagination.current_page}`
      if (state.searchText) {
        url += `&pregunta=${state.searchText}`
      }
      axiosStore.get(url).then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          const respuesta = response.data.data.map(item => {
            return {
              ...item,
              idioma: item.idioma.find(element => parseInt(element.LngId) === parseInt(lng))
            }
          })
          commit('updateQuestions', respuesta)
          commit('updatePagination', response.data)
        } else {
          commit('updateQuestions', [])
          // commit('updatePagination', { per_page: 10, current_page: 1 })
        }
      })
    },
    changeSize ({ commit, dispatch }, size) {
      commit('updateSize', size)
      dispatch('apiPreguntas')
    },
    changeCurrent ({ commit, dispatch }, current) {
      commit('updateCurrent', current)
      dispatch('apiPreguntas')
    },
    changeSearch ({ commit, dispatch }, searchText) {
      commit('updateSearchText', searchText)
      dispatch('apiPreguntas')
    },
    createPregunta ({ commit }, pregunta) {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      const message = [
        'Pregunta creada correctamente',
        'Pregunta creada correctament',
        'Question successfully created',
        'Question créée avec succès'
      ][lng - 1]
      axiosStore.get('somelier/pregunta', { params: pregunta }).then(() => {
        ElMessage.success({
          message
        })
      }).catch(error => {
        commit('')
        console.log(error)
      })
    }
  },
  getters: {
    getQuestions: state => state.questions,
    getLoading: state => state.loading,
    getPagination: state => state.pagination
  }

}

export default preguntas
