<template>
  <el-dialog
    v-model="localVisible"
    :title="title"
    :width="ancho"
    :close-on-click-modal="false"
    :show-close="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <slot name="content" />
    <template #footer>
      <span class="dialog-footer">
        <slot name="footer" />
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  name: 'modal-base',
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false
    },
    title: {
      type: String,
      require: false,
      default: ''
    },
    message: {
      type: String,
      require: false,
      default: ''
    },
    width: {
      type: [String, Number],
      require: false,
      default: '90%'
    }
  },
  emits: ['update:visible'],
  setup (props, { emit }) {
    const localVisible = computed({
      get: () => props.visible,
      set: (value) => { emit('update:visible', value) }
    })
    const ancho = ref(props.width)
    return { localVisible, ancho }
  }
}
</script>

<style scoped>

</style>
