import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      // Realizar la redirección a /tienda
      next('/tienda')
    }
  },
  {
    path: '/quienes-somos',
    name: 'QuienesSomos',
    component: () => import(/* webpackChunkName: "quienes" */ '../views/QuienesSomos.vue')
  },
  {
    path: '/envios',
    name: 'Envios',
    component: () => import(/* webpackChunkName: "envios" */ '../views/Envios.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/Contacto.vue')
  },
  {
    path: '/club',
    name: 'Club',
    component: () => import(/* webpackChunkName: "club" */ '../views/Club.vue')
  },
  {
    path: '/cliente',
    name: 'Cliente',
    component: () => import(/* webpackChunkName: "cliente" */ '../views/Cliente.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/tienda',
    name: 'Tienda',
    props: route => ({ filter: route.query.filter }),
    component: () => import(/* webpackChunkName: "tienda" */ '../views/Tienda.vue')
  },
  {
    path: '/tiendas/:id/:slug',
    name: 'Tiendas',
    component: () => import(/* webpackChunkName: "tienda-mapa" */ '../views/Tiendas-mapas.vue')
  },
  {
    path: '/tienda/:categoria/:slug',
    name: 'Producto',
    component: () => import(/* webpackChunkName: "producto" */ '../views/Producto.vue')
  },
  {
    path: '/estamos',
    name: 'Estamos',
    component: () => import(/* webpackChunkName: "estamos" */ '../views/Estamos.vue')
  },
  {
    path: '/pregunta',
    name: 'Pregunta',
    component: () => import(/* webpackChunkName: "estamos" */ '../views/Pregunta.vue')
  },
  {
    path: '/privacidad',
    name: 'Privacidad',
    component: () => import(/* webpackChunkName: "privacidad" */ '../views/Privacidad.vue')
  },
  {
    path: '/condiciones',
    name: 'Condiciones',
    component: () => import(/* webpackChunkName: "condiciones" */ '../views/Condiciones.vue')
  },
  {
    path: '/devoluciones',
    name: 'Devoluciones',
    component: () => import(/* webpackChunkName: "devoluciones" */ '../views/Devoluciones.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/Legal.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "Cookies" */ '../views/Cookies.vue')
  },
  {
    path: '/cart',
    name: 'Carrito',
    component: () => import(/* webpackChunkName: "condiciones" */ '../views/Cart.vue')
  },
  {
    path: '/favoritos',
    name: 'Favoritos',
    component: () => import(/* webpackChunkName: "condiciones" */ '../views/Favoritos.vue')
  },
  {
    path: '/cursos',
    name: 'Cursos',
    component: () => import(/* webpackChunkName: "cursos" */ '../views/Cursos.vue')
  },
  {
    path: '/:listType',
    name: 'Novedades',
    component: () => import(/* webpackChunkName: "listas" */ '../views/Listas.vue'),
    props: true
  },
  {
    path: '/redsys-pago-ok',
    name: 'redsys-ok',
    component: () => import(/* webpackChunkName: "redsys-ok" */ '../components/redsys/redsys-ok')
  },
  {
    path: '/transfer-pago-ok/:paid/:reference',
    name: 'transfer-ok',
    component: () => import(/* webpackChunkName: "transfer-ok" */ '../components/redsys/transfer-ok')
  },
  {
    path: '/paypal-pago-ok',
    name: 'paypal-ok',
    component: () => import(/* webpackChunkName: "paypal-ok" */ '../components/redsys/paypal-ok')
  },
  {
    path: '/redsys-pago-error',
    name: 'redsys-ko',
    component: () => import(/* webpackChunkName: "redsys-ko" */ '../components/redsys/redsys-ko')
  },
  {
    path: '/redsys-resultado-pago',
    name: 'redsys-notification',
    component: () => import(/* webpackChunkName: "redsys-notification" */ '../components/redsys/redsys-notification')
  },
  {
    path: '/confirm-email/:uuid',
    name: 'ConfirmEmail',
    component: () => import(/* webpackChunkName: "confirm-email" */ '../views/ConfirmEmail.vue')
  },
  {
    path: '/confirm-club/:uuid',
    name: 'ConfirmClub',
    component: () => import(/* webpackChunkName: "confirm-club" */ '../views/ConfirmClub.vue')
  },
  {
    path: '/peticion-pass/:token',
    name: 'RequirePass',
    component: () => import('../views/RequirePass')
  },
  {
    path: '/voting',
    name: 'Voting',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/Voting.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
