<template>
  <div class="grid-2-footer">
    <ul class="footer-ul-4">
      <li v-for="option in Options" :key="option.title" @click="sendGo(option.url)">
        {{ option.title }}
      </li>
    </ul>
    <div></div>
    <ul class="footer-ul-4">
      <li v-for="unique in Social" :key="unique.title" @click="sendSocial(unique.url)">
        <img :src="unique.icon" :alt="unique.title" :title="unique.title"/>
      </li>
    </ul>
  </div>
  <vue-cookie-accept-decline v-if="!cookie"
                             :debug="false"
                             :disableDecline="false"
                             :showPostponeButton="false"
                             @clicked-accept="cookieClickedAccept"
                             @clicked-decline="cookieClickedDecline"
                             @clicked-postpone="cookieClickedPostpone"
                             @removed-cookie="cookieRemovedCookie"
                             @status="cookieStatus"
                             elementId="myPanel1"
                             position="bottom-left"
                             ref="myPanel1"
                             transitionName="slideFromBottom"
                             type="floating"
  >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      {{aceptar}}
      <br />
      <span>{{informacion}}
        <a :href="apiurl" target="_blank">{{privacidad}}</a>
      </span>
    </template>

    <!-- Optional -->
    <template #declineContent>{{cancelText}}</template>

    <!-- Optional -->
    <template #acceptContent>{{acceptText}}</template>
  </vue-cookie-accept-decline>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, inject } from 'vue'
// import { useRouter } from 'vue-router'

export default {
  name: 'footer-page',
  setup () {
    const store = useStore()
    // const router = useRouter()
    const cookie = ref(null)
    const emitter = inject('emitter')
    const lang = computed(() => store.getters['Init/getLng'])
    const cancelText = computed(() => ['Cancelar', 'Cancel·lar', 'Cancel', 'Annuler'][lang.value - 1])
    const acceptText = computed(() => ['Aceptar', 'Acceptar', 'Done', 'Accepter'][lang.value - 1])
    const aceptar = computed(() => [
      'Utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarle publicidad relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas).',
      'Utilitzem cookies pròpies i de tercers per analitzar els nostres serveis i mostrar-te publicitat relacionada amb les teves preferències en base a un perfil elaborat a partir dels teus hàbits de navegació (per exemple, pàgines visitades).',
      'We use our own and third-party cookies to analyze our services and show you advertising related to your preferences based on a profile made from your browsing habits (for example, pages visited).',
      'Nous utilisons nos propres cookies et ceux de tiers pour analyser nos services et vous montrer des publicités liées à vos préférences en fonction d\'un profil établi à partir de vos habitudes de navigation (par exemple, les pages visitées).'
    ][lang.value - 1])
    const informacion = computed(() => [
      'Puedes aceptar todas las cookies pulsando el botón "Aceptar", o denegar pulsando el botón "Cancelar". Puede leer nuestra Política de Cookies clicando ',
      'Pots acceptar totes les cookies clicant el botó "Acceptar", o denegar clicant el botó "Cancel·lar". Podeu llegir la nostra Política de Cookies clicant ',
      'You can accept all cookies by clicking the "Accept" button, or refuse by clicking the "Cancel" button. You can read our Cookies Policy by clicking ',
      'Vous pouvez accepter tous les cookies en cliquant sur le bouton "Accepter", ou refuser en cliquant sur le bouton "Annuler". Vous pouvez lire notre politique de cookies en cliquant '
    ][lang.value - 1])
    const privacidad = computed(() => [
      'aquí',
      'aquí',
      'here',
      'ici'
    ][lang.value - 1])
    const Social = computed(() => store.getters['footer/getSocial'])
    const Options = computed(() => store.getters['footer/getOptions'])
    const apiurl = process.env.VUE_APP_API ? 'https://localhost:8080/privacidad' : 'https://www.winepalace.es/cookies'
    const cookieClickedAccept = () => {
      cookie.value = 'true'
      localStorage.setItem('accept', 'true')
    }
    const cookieClickedDecline = () => {
      cookie.value = null
      localStorage.removeItem('accept')
    }
    const cookieClickedPostpone = () => {
      cookie.value = null
      localStorage.removeItem('accept')
    }
    const cookieRemovedCookie = () => {
      cookie.value = null
      localStorage.removeItem('accept')
    }
    const cookieStatus = (value) => {
      console.log('value', value)
    }
    const sendGo = (url) => {
      window.location.href = url
    }
    const sendSocial = (url) => { window.open(url, '_blank') }
    onMounted(() => {
      const tmp = localStorage.getItem('accept')
      cookie.value = !tmp ? null : tmp
      emitter.on('language', () => {
        store.dispatch('footer/getFooterMenu')
      })
    })
    return {
      Options,
      Social,
      sendGo,
      cookieStatus,
      cookieClickedDecline,
      cookieClickedAccept,
      cookieClickedPostpone,
      cookieRemovedCookie,
      apiurl,
      cookie,
      sendSocial,
      aceptar,
      informacion,
      privacidad,
      cancelText,
      acceptText
    }
  }
}
</script>

<style scoped>

</style>
