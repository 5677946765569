import { createStore } from 'vuex'
import menu from '@/store/modules/menu'
import uvas from '@/store/modules/uvas'
import somos from '@/store/modules/somos'
import envios from '@/store/modules/envios'
import contacto from '@/store/modules/contacto'
import footer from '@/store/modules/footer'
import club from '@/store/modules/club'
import cliente from '@/store/modules/cliente'
import faq from '@/store/modules/faq'
import storeMenu from '@/store/modules/tienda/menu'
import products from '@/store/modules/tienda/products'
import categories from '@/store/modules/tienda/products/categories'
import estamos from '@/store/modules/estamos'
import Init from '@/store/modules/Init'
import preguntas from '@/store/modules/preguntas'
import legal from '@/store/modules/legal'
import listas from '@/store/modules/listas'
import carrito from '@/store/modules/tienda/products/carrito'
import descuento from '@/store/modules/tienda/descuento'
import favorites from '@/store/modules/tienda/favorites'
import country from '@/store/modules/country'
import invoice from '@/store/modules/facturas'
import eventos from '@/store/modules/eventos'
import card from '@/store/modules/redsys'
import banners from '@/store/modules/banners'
import transfer from '@/store/modules/transfer'
import voting from '@/store/modules/voting'

export default createStore({
  modules: {
    menu,
    uvas,
    somos,
    envios,
    contacto,
    footer,
    club,
    cliente,
    faq,
    storeMenu,
    products,
    categories,
    estamos,
    Init,
    preguntas,
    legal,
    listas,
    carrito,
    descuento,
    favorites,
    country,
    invoice,
    eventos,
    card,
    banners,
    transfer,
    voting
  }
})
