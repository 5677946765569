import axiosStore from '@/Api/ApiConfig'

const categories = {
  namespaced: true,
  state: {
    categories: [],
    category: '',
    title: 'Categorias'
  },
  mutations: {
    updateCategories: (state, category) => {
      state.categories = category
    },
    updateCategory: (state, category) => {
      state.category = category
    },
    updateActive: (state, id) => {
      state.categories.forEach((item, index) => {
        state.categories[index].status = false
        state.categories[index].subfamilia.forEach((element, posicion) => {
          state.categories[index].subfamilia[posicion].status = false
          state.categories[index].subfamilia[posicion].variedades.forEach((nodo, lugar) => {
            state.categories[index].subfamilia[posicion].variedades[lugar].status = false
          })
        })
      })
      if (id === '-1' || id === -1) {
        return
      }
      const position = state.categories.findIndex(item => item.Id === id)
      if (position > -1) {
        state.categories[position].status = true
      }
    },
    updateSubActive: (state, id) => {
      const categoriaActiva = state.categories.findIndex(item => item.status)
      if (categoriaActiva > -1) {
        state.categories[categoriaActiva].subfamilia.forEach((item, index) => {
          state.categories[categoriaActiva].subfamilia[index].status = false
          state.categories[categoriaActiva].subfamilia[index].variedades.forEach((element, position) => {
            state.categories[categoriaActiva].subfamilia[index].variedades[position].status = false
          })
        })
        const position = state.categories[categoriaActiva].subfamilia.findIndex(item => item.Id === id)
        if (position > -1) {
          state.categories[categoriaActiva].subfamilia[position].status = true
        }
      }
    },
    updateVariedad: (state, id) => {
      const categoriaActiva = state.categories.findIndex(item => item.status)
      if (categoriaActiva > -1) {
        const subActiva = state.categories[categoriaActiva].subfamilia.findIndex(item => item.status)
        if (subActiva > -1) {
          state.categories[categoriaActiva].subfamilia[subActiva].variedades.forEach((item, index) => {
            state.categories[categoriaActiva].subfamilia[subActiva].variedades[index].status = false
          })
          const search = state.categories[categoriaActiva].subfamilia[subActiva].variedades.findIndex(item => item.Id === id)
          if (search > -1) {
            state.categories[categoriaActiva].subfamilia[subActiva].variedades[search].status = true
          }
        }
      }
    }
  },
  actions: {
    apiCategories: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('familias', { params: { lng: lng.toString() } }).then(result => {
        let Categories = []
        const response = []
        if (result.data) {
          Categories = result.data.map(item => {
            return {
              ...item,
              status: false,
              subfamilia: item.subfamilia.map(item => {
                return {
                  ...item,
                  status: false,
                  variedades: item.variedades.map(item => {
                    return {
                      ...item,
                      status: false
                    }
                  })
                }
              })
            }
          })
          // Categories = Categories.filter(item => item.articulos_count > 0)
          response[3] = {
            Id: 1040511,
            Nombre: ['Aperitivos', 'Aperitius', 'Snacks', 'Snacks'][lng - 1],
            Visible: '1',
            SectionId: '1040511',
            articulos_count: '2',
            idioma: [],
            subfamilia: []
          }
          Categories.forEach((item, index) => {
            item.subfamilia.forEach((el, indice) => {
              el.variedades.forEach((element, count) => {
                if (element.articulos_count === '0') {
                  Categories[index].subfamilia[indice].variedades.splice(count, 1)
                }
              })
              if (el.articulos_count === '0') {
                Categories[index].subfamilia.splice(indice, 1)
              }
            })
            if (item.articulos_count === '0') {
              Categories.splice(index, 1)
            }
            if (item.Id === 10405) {
              response[0] = item
            }
            if (item.Id === 10406) {
              response[1] = item
            }
            if (item.Id === 10407) {
              response[2] = item
            }
            if (item.Id === 10404) {
              response[4] = item
            }
          })
        }

        commit('updateCategories', response)
      })
    },
    changeActive: ({ commit }, id) => {
      commit('updateActive', id)
    },
    changeSubActive: ({ commit }, id) => {
      commit('updateSubActive', id)
    },
    changeVarActive: ({ commit }, id) => {
      commit('updateVariedad', id)
    }
  },
  getters: {
    getCategories: state => state.categories,
    getTitle: state => state.title
  }

}

export default categories
