import axiosStore from '../../../Api/ApiConfig'

const envios = {
  namespaced: true,
  state: {
    title: '',
    optionsList: []
  },
  mutations: {
    updateList: (state, list) => {
      if (Array.isArray(list)) {
        state.optionsList = list
      }
    },
    updateTitle: (state, title) => { state.title = title }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('envios', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateList', texto.optionsList)
        commit('updateTitle', texto.title)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    optionsList: (state) => state.optionsList,
    getTitle: (state) => state.title
  }

}

export default envios
