import axiosStore from '../../../Api/ApiConfig'

const contacto = {
  namespaced: true,
  state: {
    title: '',
    Cards: [],
    placeholder: {},
    buttonText: ''
  },
  mutations: {
    updateTitle: (state, title) => {
      state.title = title
    },
    updateCards: (state, cards) => {
      if (Array.isArray(cards)) {
        state.Cards = cards
      }
    },
    updatePlaceHolder: (state, placeholder) => {
      state.placeholder = placeholder
    },
    updateButtonText: (state, text) => {
      state.buttonText = text
    }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('contacto', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateCards', texto.Cards)
        commit('updateTitle', texto.title)
        commit('updatePlaceHolder', texto.placeholder)
        commit('updateButtonText', texto.buttonText)
      }).catch(error => {
        console.log(error)
      })
    },
    apiSend: ({ _commit }, contacto) => {
      const name = contacto.name
      const email = contacto.email
      const phone = contacto.phone
      const subject = contacto.subject
      const message = contacto.message
      axiosStore.get('contact?name=' + name + '&email=' + email + '&phone=' + phone + '&subject=' + subject + '&message=' + message).then(response => {
        console.log('contacto guardado')
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getTitle: (state) => state.title,
    getPlaceholder: (state) => state.placeholder,
    getButtonText: (state) => state.buttonText,
    getCards: (state) => state.Cards
  }

}

export default contacto
