<template>
  <header>
    <div class="home">
      <menu-superior ref="superior"/>
    </div>
  </header>
  <router-view @change="cambio()"/>
  <!--<router-view v-slot="{ Component }">
    <transition name="component-fade" mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>-->
  <span v-if="status">{{ current }}</span>
  <footer :class="{ tienda: !validPath}">
    <pie-tienda-responsive />
    <pie/>
  </footer>
  <advertencia />
</template>

<script>
import menuSuperior from '@/components/pagine/parts/menu-superior'
import footer from '@/components/pagine/parts/footer'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import advertencia from '@/components/individual/advertencia'
import pieTiendaResponsive from '@/components/pagine/parts/pie-tienda-responsive'
// import Home from '@/views/Home'

export default {
  name: 'App',
  components: {
    menuSuperior,
    pie: footer,
    advertencia,
    pieTiendaResponsive
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const status = !!process.env.VUE_APP_API
    const current = computed(() => route.fullPath)
    const paths = ['/', '/quienes-somos', '/envios', '/contacto', '/club', '/cliente', '/faq']
    const validPath = computed(() => paths.includes(current.value))
    const superior = ref('')
    const locale = computed(() => store.getters['Init/getLanguageIso'])
    const cambio = () => {
      superior.value.drawneroff()
    }
    onMounted(() => {
      store.dispatch('Init/initLng')
      store.dispatch('uvas/ApiGet')
      store.dispatch('menu/ApiGet')
      store.dispatch('footer/getFooterMenu')
      window.scrollTo(0, 0)
    })
    return {
      current,
      status,
      validPath,
      locale,
      cambio,
      superior
    }
  }
}
</script>
