import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mitt from 'mitt'
import AOS from 'aos'
import mask from 'vuejs-mask'
import { createHead } from '@vueuse/head'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import 'aos/dist/aos.css'
import ElementPlus from 'element-plus'
import { Aim, CirclePlus, Remove, ArrowDownBold, ArrowUpBold, ArrowRightBold, FullScreen, Select, ShoppingCart, InfoFilled, CirclePlusFilled, RemoveFilled, Delete } from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/assets/style/style.scss'

import es from 'element-plus/es/locale/lang/es'
import ca from 'element-plus/es/locale/lang/ca'
import en from 'element-plus/es/locale/lang/en'
import fr from 'element-plus/es/locale/lang/fr'

const emitter = mitt()
let lng = localStorage.getItem('lng')
if (!lng) { lng = 1 } else { lng = parseInt(lng) }

const lang = [es, ca, en, fr][lng - 1]

const head = createHead()

const app = createApp(App)
app.use(ElementPlus, {
  locale: lang
})
app.use(store)
app.use(router)
app.use(AOS.init())
app.use(mask)
app.use(head)
app.component('Aim-icon', Aim)
app.component('CirclePlus', CirclePlus)
app.component('RemoveFilled', RemoveFilled)
app.component('Delete-icon', Delete)
// app.component('Remove', Remove)
app.component('Remove-icon', Remove)
app.component('ArrowDownBold', ArrowDownBold)
app.component('ArrowUpBold', ArrowUpBold)
app.component('ArrowRightBold', ArrowRightBold)
app.component('FullScreen', FullScreen)
app.component('ElOK', Select)
app.component('ShoppingCart', ShoppingCart)
app.component('InfoFilled', InfoFilled)
app.component('CirclePlusFilled', CirclePlusFilled)
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
app.provide('emitter', emitter)

app.mount('#app')
