import axiosStore from '../../../Api/ApiConfig'
import { ElMessage } from 'element-plus'

const cliente = {
  namespaced: true,
  state: {
    token: false,
    requirePass: false,
    loading: false,
    title: '',
    login: {
      title: '',
      icon: '',
      subTitle: '',
      placeholder: {
        email: '',
        pass: ''
      },
      buttonText: '',
      socialText: '',
      socialIcons: []
    },
    register: {
      title: '',
      subTitle: '',
      passConditions: '',
      error: {
        nacimiento: '',
        no18: '',
        // nifvalido: '',
        // nifnecesario: '',
        // empresa: '',
        // direccion: '',
        // postal: '',
        // localidad: '',
        // provincia: '',
        passNecesaria: '',
        repita: '',
        coincide: '',
        emailNecesario: '',
        emailFormato: '',
        captcha: '',
        form: ''
      },
      rules: {
        nombreObligatorio: '',
        apellidoObligatorio: ''
      },
      placeholder: {
        name: '',
        surName: '',
        email: '',
        pass: '',
        newsletter: '',
        twoPass: '',
        birthday: ''
      },
      buttonText: ''
    },
    novaDirDeFactura: false,
    textLogin: '',
    textRegister: '',
    status: {
      login: '',
      user: ''
    },
    buttons: null,
    mis: null
  },
  mutations: {
    updateTitle: (state, title) => {
      state.title = title
    },
    updateLogin: (state, login) => {
      state.login = login
    },
    updateRegister: (state, register) => {
      state.register = register
    },
    updateTextLogin: (state, textLogin) => {
      state.textLogin = textLogin
    },
    updateTextRegister: (state, textRegister) => {
      state.textRegister = textRegister
    },
    updateApiLogin: (state, login) => {
      state.status.login = login.cookie
      state.status.user = login.cliente
      localStorage.setItem('tienda', login.cookie)
    },
    updatePerfil: (state, perfil) => {
      state.status.user.perfil = perfil
    },
    updateUser: (state, user) => {
      state.status.user = user
    },
    updateLoading: (state, loading) => {
      state.loading = loading
    },
    updateCard: (state, card) => {
      state.status.user.card = card
    },
    updateButtons: (state, buttons) => { state.buttons = buttons },
    updateMis: (state, mis) => { state.mis = mis },
    updateStatusToken: (state, token) => {
      state.token = token
    },
    updateRequirePass: (state, pass) => { state.requirePass = pass },
    agregarDireccion (state) {
      state.novaDirDeFactura = true
    },
    quitarDireccion (state) {
      state.novaDirDeFactura = false
    }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) {
        lng = 1
      } else {
        lng = parseInt(lng)
      }
      axiosStore.get('formClient', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateLogin', texto.login)
        commit('updateTitle', texto.title)
        commit('updateRegister', texto.register)
        commit('updateTextLogin', texto.textLogin)
        commit('updateTextRegister', texto.textRegister)
        commit('updateButtons', texto.buttons)
        commit('updateMis', texto.mis)
      }).catch(error => {
        console.log(error)
      })
    },
    apiLogin: ({
      commit,
      dispatch
    }, {
      pass,
      correo
    }) => {
      axiosStore.get('user/login', {
        params: {
          correo,
          password: pass
        }
      }).then(response => {
        commit('updateApiLogin', response.data)
        dispatch('apiUserPerfil')
      })
        .catch(error => {
          if (error.data) {
            console.log('data', error.data)
          } else {
            console.log('nodata', error)
          }
        })
    },
    apiUserPerfil: ({
      commit,
      state
    }) => {
      axiosStore.get(`user/login/perfil?cookie=${state.status.login}`).then(response => {
        commit('updatePerfil', response.data)
      }).catch(error => console.log(error))
    },
    apiAutoLogin: ({
      dispatch,
      commit,
      state
    }) => {
      const cookie = verifyCookie(state, commit)
      if (cookie === undefined) {
        return
      }
      axiosStore.get(`user/login/auto?cookie=${cookie}`).then(response => {
        if (response.data) {
          commit('updateApiLogin', response.data)
          dispatch('apiUserPerfil')
        } else {
          const status = {
            login: '',
            user: ''
          }
          commit('updateApiLogin', status)
        }
      }).catch(error => console.log(error))
    },
    apiLogout: ({
      dispatch,
      state
    }) => {
      axiosStore.get(`user/login/logout?cookie=${state.status.login}`).then(() => dispatch('apiAutoLogin')).catch(error => console.log(error))
    },
    ApiRegistro: ({
      commit,
      dispatch
    }, {
      pass,
      name,
      surname,
      twoPass,
      email,
      newletter,
      dia,
      mes,
      ano
      // nif,
      // company,
      // direccion,
      // codigo,
      // localidad,
      // provincia
    }) => {
      if (!name || !surname || !pass || !email || !twoPass || !dia || !ano || !mes) {
        return
      }
      axiosStore.get('user/register', {
        params: {
          pass,
          name,
          surname,
          twoPass,
          email,
          newletter,
          dia,
          mes,
          ano
          // nif,
          // company,
          // direccion,
          // codigo,
          // localidad,
          // provincia
        }
      })
        .then(response => {
          let lng = localStorage.getItem('lng')
          if (!lng) { lng = 1 } else { lng = parseInt(lng) }
          const message = [
            'El registro se ha realizado correctamente, por favor revise su correo electrónico para validar su cuenta',
            'El registre s\'ha realitzat correctament, si us plau revisi el seu correu electrònic per a validar el seu compte',
            'Registration has been successful, please check your email to validate your account.',
            'L\'inscription a réussi, veuillez vérifier votre e-mail pour valider votre compte.'
          ][lng - 1]
          ElMessage.success(message)
          commit('updateApiLogin', response.data)
          dispatch('apiUserPerfil')
        })
        .catch(error => {
          console.log(error)
        })
    },
    ApiPassword: ({ commit }, {
      email,
      oldPass,
      newPass,
      verifyPass
    }) => {
      if (!email || !oldPass || !newPass || !verifyPass) {
        return
      }
      axiosStore.get('user/pass', {
        params: {
          email,
          oldPass,
          newPass,
          verifyPass
        }
      })
        .then(response => {
          let lng = localStorage.getItem('lng')
          if (!lng) { lng = 1 } else { lng = parseInt(lng) }
          const message = [
            'Se ha cambiado la contraseña correctamente',
            'S\'ha canviat la contrasenya correctament',
            'Password has been changed successfully',
            'Le mot de passe a été changé avec succès'
          ][lng - 1]
          ElMessage.success(message)
        })
        .catch(error => console.log(error))
    },
    ApiRemoveAddress: ({ commit, state, dispatch }, id) => {
      const cookie = verifyCookie(state, commit)
      if (cookie === undefined) {
        return
      }
      axiosStore.get('user/address/remove', { params: { id, cookie } }).then(response => {
        let lng = localStorage.getItem('lng')
        if (!lng) { lng = 1 } else { lng = parseInt(lng) }
        const message = [
          'La dirección ha sido borrada',
          'S\'ha canviat la contrasenya correctament',
          'The address has been deleted',
          'L\'adresse a été supprimée'
        ][lng - 1]
        ElMessage.success(message)
        dispatch('apiUserPerfil')
      }).catch(error => console.log(error))
    },
    ApiChangeAddress: ({ dispatch }, {
      nombre,
      apellidos,
      codigo,
      localidad,
      provincia,
      pais,
      telefono,
      movil,
      nif,
      compania,
      calle,
      observacion,
      idDireccion
    }) => {
      if (!codigo || !localidad || !provincia || !pais || !calle || !idDireccion) {
        return
      }
      axiosStore.get('user/update/address', {
        params: {
          nombre,
          apellidos,
          codigo,
          localidad,
          provincia,
          pais,
          telefono,
          movil,
          nif,
          compania,
          calle,
          observacion,
          idDireccion
        }
      }).then(response => {
        let lng = localStorage.getItem('lng')
        if (!lng) { lng = 1 } else { lng = parseInt(lng) }
        const message = [
          'Cambios de dirección realizados correctamente',
          'Canvis de direcció realitzats correctament',
          'Correctly performed changes of direction',
          'Les changements de direction sont effectués correctement'
        ][lng - 1]
        ElMessage.success(message)
        dispatch('apiUserPerfil')
      }).catch(error => console.log(error))
    },
    ApiPersonal ({
      commit,
      state
    }, {
      ano,
      dia,
      mes,
      email,
      name,
      newletter,
      surname
    }) {
      if (!ano || !dia || !mes || !email || !name || !surname) {
        return
      }
      const cookie = verifyCookie(state, commit)
      commit('updateLoading', true)
      axiosStore.get('user/update/personal', {
        params: {
          ano,
          dia,
          mes,
          email,
          name,
          newletter,
          surname,
          cookie
        }
      })
        .then(response => {
          let lng = localStorage.getItem('lng')
          if (!lng) { lng = 1 } else { lng = parseInt(lng) }
          const message = [
            'El registro se ha actualizado correctamente',
            'El registre s\'ha actualitzat correctament',
            'The registry has been successfully updated',
            'Le registre a été mis à jour avec succès'
          ][lng - 1]
          ElMessage.success(message)
          commit('updateUser', response.data)
          commit('updateLoading', false)
        })
        .catch(error => {
          commit('updateLoading', false)
          console.log(error)
        })
    },
    ApiCreateAddress: ({ dispatch, state, commit }, {
      nombre, apellidos, codigo, localidad, provincia, pais, telefono, movil, nif, compania, calle, observacion
    }) => {
      if (!codigo || !localidad || !provincia || !pais || !calle || !nombre || !apellidos) {
        return
      }
      const cookie = verifyCookie(state, commit)
      if (cookie === undefined) {
        return
      }
      axiosStore.get('user/create/address', {
        params: {
          nombre,
          apellidos,
          codigo,
          localidad,
          provincia,
          pais,
          telefono,
          movil,
          nif,
          compania,
          calle,
          observacion,
          cookie
        }
      }).then(response => {
        let lng = localStorage.getItem('lng')
        if (!lng) { lng = 1 } else { lng = parseInt(lng) }
        const message = [
          'Cambios de dirección realizados correctamente',
          'Canvis de direcció realitzats correctament',
          'Correctly performed changes of direction',
          'Les changements de direction sont effectués correctement'
        ][lng - 1]
        ElMessage.success(message)
        dispatch('apiUserPerfil')
      }).catch(error => console.log(error))
    },
    ApiConfirmEmail: (_context, cookie) => {
      axiosStore.get('email/confirm?cookie=' + cookie).then(result => {
        let lng = localStorage.getItem('lng')
        if (!lng) { lng = 1 } else { lng = parseInt(lng) }
        const message = [
          'El correo electrónico ha sido confirmado correctamente',
          'El correu electrònic ha estat confirmat correctament',
          'The e-mail has been successfully confirmed',
          'L\'e-mail a été confirmé avec succès'
        ][lng - 1]
        ElMessage.success(message)
      }).catch(error => { console.log(error) })
    },
    ApiConfirmCard: (_context, cookie) => {
      axiosStore.get('card/confirm?cookie=' + cookie).then(result => {
        let lng = localStorage.getItem('lng')
        if (!lng) { lng = 1 } else { lng = parseInt(lng) }
        const message = [
          'La tarjeta ha sido confirmada correctamente',
          'La targeta ha estat confirmada correctament',
          'The card has been successfully confirmed',
          'La carte a été confirmée avec succès'
        ][lng - 1]
        ElMessage.success(message)
      }).catch(error => { console.log(error) })
    },
    GetCard: ({ commit }, data) => {
      axiosStore.get('card/get', { params: data }).then(response => {
        if (response.data) {
          commit('updateCard', response.data)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    ApiRequirePass: ({ _commit }, email) => {
      axiosStore.get('newPass', { params: { email } }).then(response => {
        ElMessage.success('Se le ha enviado un correo electrónico')
      }).catch(error => {
        console.log('error', error)
      })
    },
    ApiCheckToken: ({ commit }, token) => {
      axiosStore.get('verifyToken', { params: { token } })
        .then(() => {
          commit('updateStatusToken', true)
        })
        .catch(() => {
          commit('updateStatusToken', false)
        })
    },
    ApiSendChangePass: ({ commit }, data) => {
      if (!data.pass || !data.same || !data.token) {
        return
      }
      axiosStore.get('changePass', { params: data }).then(result => {
        commit('updateRequirePass', true)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getLogin: (state) => state.login,
    getTitle: (state) => state.title,
    getRegister: (state) => state.register,
    getTextLogin: (state) => state.textLogin,
    getTextRegister: (state) => state.textRegister,
    getStatusLogin: state => state.status.login,
    getUserLogin: state => state.status.user,
    getLoading: state => state.loading,
    getCardUser: state => state.status.user ? state.status.user.card : {},
    getTextButtons: state => state.buttons,
    getTextMis: state => state.mis,
    getStatusToken: state => state.token,
    getRequirePass: state => state.requirePass,
    getDirNovaFactu: state => state.novaDirDeFactura
  }

}

const verifyCookie = (state, commit) => {
  const cookie = localStorage.getItem('tienda')
  if (!state.status.login) {
    commit('updateLogin', cookie)
    return cookie
  }
  return state.status.login
}

export default cliente
