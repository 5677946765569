<template>
  <div class="container-tablet">
    <div  class="menu-tablet">
      <ul class="options-tablet" id="options-tablet">
        <li v-for="option in listOptionsComputed" :key="option.title" @click="changeCurrent(option)">
          <div class="container-uva"><div class="uva"></div></div><a href="#" :data-hover="option.title">{{option.title}}</a>
          <!--<router-link :to="{path: option.url}" >{{option.title}}</router-link>-->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute /*, useRouter */ } from 'vue-router'
export default {
  name: 'menu-superior-tablet',
  setup () {
    const store = useStore()
    const route = useRoute()
    // const router = useRouter()
    const position = ref(0)
    const status = ref(false)
    const vision = computed(() => 'opacity: ' + ((40 - position.value) * 2.5 / 100))
    const menuVision = computed(() => 'opacity: ' + (position.value * 2.5 / 100))
    const current = computed(() => route.fullPath)
    const togle = () => {
      if (!status.value) {
        // position.value = 0
        for (let i = 0; i < 40; i += 2) {
          setTimeout(() => {
            position.value += 2
            if (position.value > 40) {
              position.value = 40
            }
          }, 10 * i)
        }
      } else {
        // position.value = 60
        for (let i = 0; i < 60; i += 2) {
          setTimeout(() => {
            position.value -= 2
            if (position.value < 0) {
              position.value = 0
            }
          }, 10 * i)
        }
      }
      status.value = !status.value
    }
    const listOptionsComputed = computed(() => {
      return store.getters['menu/menuList'].map((item, index) => {
        return {
          ...item,
          index
        }
      })
    })
    const selectMenu = () => {
      const Lposition = listOptionsComputed.value.findIndex(item => item.url === current.value)
      if (Lposition > -1) {
        const clearPosition = Lposition || 0
        document.getElementById('options-tablet').children[clearPosition].classList.add('current')
      }
    }
    const clearCurrent = () => {
      const element = document.getElementById('options-tablet')
      const children = element.children
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        child.classList.remove('current')
      }
    }
    const changeCurrent = ({ index, url }) => {
      const ClearIndex = index || 0
      store.dispatch('menu/changeMenuOption', ClearIndex)
      clearCurrent()
      selectMenu()
      if (url && url !== current.value) {
        window.location.href = url
      }
      //  :href="option.url===current ? '#' : option.url"
    }
    onMounted(() => {
      setTimeout(() => {
        clearCurrent()
        selectMenu()
      }, 500)
    })
    return {
      position,
      togle,
      vision,
      status,
      menuVision,
      listOptionsComputed,
      changeCurrent
    }
  }
}
</script>

<style scoped>

</style>
