import axiosStore from '@/Api/ApiConfig'
import { ElMessage } from 'element-plus'

const invoice = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    apiInvoicePdf: ({ _commit }, id) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      const message = [
        'Solicitud de factura enviada',
        'Sol·licitud de factura enviada',
        'Invoice request sent',
        'Envoi de la demande de facture'
      ][lng - 1]
      axiosStore.get('factura/pdf', { params: { id } }).then(response => {
        ElMessage.success(message)
      })
    }
  },
  getters: {}

}

export default invoice
