import axiosStore from '../../../Api/ApiConfig'

const faq = {
  namespaced: true,
  state: {
    title: '',
    options: []
  },
  mutations: {
    updateOptions: (state, options) => { state.options = options },
    updateTitle: (state, title) => { state.title = title }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('faq', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateOptions', texto.options)
        commit('updateTitle', texto.title)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getOptions: (state) => state.options,
    getTitle: (state) => state.title
  }

}

export default faq
