import axiosStore from '@/Api/ApiConfig'

const transfer = {
  namespaced: true,
  state: {
    estado: false
  },
  mutations: {
    updateStatus: (state, estado) => { state.estado = estado }
  },
  actions: {
    apiPay: ({ commit }, data) => {
      axiosStore.get('transfer/pay', { params: data })
        .then(response => {
          commit('updateStatus', true)
          window.location.href = '/transfer-pago-ok/' + response.data.total + '/' + response.data.invoice
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  getters: {
    getEstado: state => state.estado
  }

}

export default transfer
