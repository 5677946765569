import axiosStore from '@/Api/ApiConfig'
import countries from '@/assets/js/country/cc.json'
import estados from '@/assets/js/country/states.json'
import ciudades from '@/assets/js/country/cities.json'

const country = {
  namespaced: true,
  state: {
    country: [],
    cities: [],
    states: []
  },
  mutations: {
    updateCountry: (state, country) => { state.country = country.filter(item => item.iso !== null) },
    updateCities: (state, cities) => { state.cities = cities },
    updateStates: (state, states) => { state.states = states }
  },
  actions: {
    ApiList: ({ commit }) => {
      axiosStore.get('country/list').then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const totalList = []
          response.data.forEach(pais => {
            const jsonData = countries.countries.find(item => item.name === pais.idioma[0].Nombre)
            if (jsonData) {
              totalList.push({
                label: pais.idioma[0].Nombre,
                value: pais.Id,
                iso: jsonData.iso || null,
                pais,
                json: jsonData
              })
            }
          })
          commit('updateCountry', totalList)
        } else {
          commit('updateCountry', [])
        }
      })
    },
    ApiStates: ({ commit }, country) => {
      const list = estados.states.filter(item => item.id_country === country)
      if (list) {
        commit('updateStates', list.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.id
          }
        }).sort(compare))
      } else {
        commit('updateStates', [])
      }
    },
    ApiCities: ({ commit }, id) => {
      const list = ciudades.cities.filter(item => item.id_state === id)
      if (list) {
        commit('updateCities', list.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.id
          }
        }).sort(compare))
      } else {
        commit('updateCities', [])
      }
    }
  },
  getters: {
    getCountry: state => state.country,
    getStates: state => state.states,
    getCities: state => state.cities
  }

}

function compare (a, b) {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
}

export default country
