import axiosStore from '@/Api/ApiConfig'
import { ElMessage } from 'element-plus'

const club = {
  namespaced: true,
  state: {
    title: '',
    subTitle: '',
    target: '',
    targetText: '',
    buttonText: '',
    features: [],
    modal: false,
    messages: {},
    modalMessage: {}
  },
  mutations: {
    updateTitle: (state, title) => {
      state.title = title
    },
    updateSubTitle: (state, subTitle) => {
      state.subTitle = subTitle
    },
    updateTarget: (state, target) => {
      state.target = target
    },
    updateTargetText: (state, targetText) => {
      state.targetText = targetText
    },
    updateButtonText: (state, buttonText) => {
      state.buttonText = buttonText
    },
    updateFeatures: (state, features) => {
      state.features = features
    },
    updateModal: (state, modal) => { state.modal = modal },
    updateMessages: (state, messages) => { state.messages = messages },
    updateModalMessages: (state, messages) => { state.modalMessage = messages }
  },
  actions: {
    apiGet: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('club', { params: { lng: lng.toString() } }).then(response => {
        const texto = response.data
        commit('updateSubTitle', texto.subTitle)
        commit('updateTitle', texto.title)
        commit('updateTarget', texto.target)
        commit('updateTargetText', texto.targetText)
        commit('updateButtonText', texto.buttonText)
        commit('updateFeatures', texto.features)
        commit('updateModalMessages', texto.messages)
      }).catch(error => {
        console.log(error)
      })
    },
    apiRegister: ({ commit }, data) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      const message = [
        'La tarjeta ha sido creada. Revise su correo electrónico para confirmar el correo utilizado',
        'La targeta ha estat creada. Revisi el seu correu electrònic per a confirmar el correu utilitzat',
        'The card has been created. Check your email address to confirm the email used',
        'La carte a été créée. Vérifiez votre adresse e-mail pour confirmer l\'adresse e-mail utilisée'
      ][lng - 1]
      axiosStore.get('club/create', {
        params: data
      }).then(response => {
        ElMessage.success(message)
        commit('updateModal', false)
      }).catch(error => {
        console.log(error)
        if (error.response.data) {
          ElMessage.error(error.response.data)
        }
      })
    },
    apiModal: ({ commit }, modal) => { commit('updateModal', modal) },
    apiMessages: ({ commit }) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get('club/messages', { params: { lng: lng.toString() } })
        .then(response => { commit('updateMessages', response.data) })
        .catch(error => { console.log(error) })
    }
  },
  getters: {
    getTitle: (state) => state.title,
    getsubTitle: (state) => state.subTitle,
    getTarget: (state) => state.target,
    getTargetText: (state) => state.targetText,
    getButtonText: (state) => state.buttonText,
    getFeatures: (state) => state.features,
    getModal: state => state.modal,
    getMessages: state => state.messages,
    getModalMessages: state => state.modalMessage
  }

}

export default club
