import axiosStore from '@/Api/ApiConfig'

const listas = {
  namespaced: true,
  state: {
    novedades: [],
    recomendados: [],
    viajar: [],
    topVentas: [],
    ofertas: [],
    lotes: [],
    descubre: []
  },
  mutations: {
    updateNovedades: (state, novedades) => { state.novedades = novedades },
    updateRecomendados: (state, recomendados) => { state.recomendados = recomendados },
    updateViajar: (state, viajar) => { state.viajar = viajar },
    updateTopVentas: (state, topVentas) => { state.topVentas = topVentas },
    updateOfertas: (state, ofertas) => { state.ofertas = ofertas },
    updateLotes: (state, lotes) => { state.lotes = lotes },
    updateDescubre: (state, descubre) => { state.descubre = descubre }
  },
  actions: {
    apiListas: ({ commit }, type) => {
      let lng = localStorage.getItem('lng')
      if (!lng) { lng = 1 } else { lng = parseInt(lng) }
      axiosStore.get(type, { params: { lng: lng.toString() } }).then(response => {
        const typeCommit = type.at(0).toUpperCase() + type.substring(1)
        if (response.data.length > 0) {
          commit('update' + typeCommit, response.data)
        } else {
          commit('update' + typeCommit, [])
        }
      }).catch(error => console.log(error))
    },
    apiListFull: ({ dispatch }) => {
      ['novedades', 'recomendados', 'topVentas', 'ofertas', 'descubre'].forEach(item => {
        dispatch('apiListas', item)
      })
    }
  },
  getters: {
    getFullLista: state => {
      return {
        novedades: state.novedades,
        recomendados: state.recomendados,
        viajar: state.viajar,
        topVentas: state.topVentas,
        ofertas: state.ofertas,
        lotes: state.lotes,
        descubre: state.descubre
      }
    },
    getNovedades: state => state.novedades,
    getRecomendados: state => state.recomendados,
    getViajar: state => state.viajar,
    getTopVentas: state => state.topVentas,
    getOfertas: state => state.ofertas,
    getLotes: state => state.lotes,
    getDescubre: state => state.descubre
  }
}

export default listas
